import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {InterpreterComponent} from '../modules/shared/components/interpreter/interpreter.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateInterpreterGuard implements CanDeactivate<InterpreterComponent> {
  canDeactivate(component: InterpreterComponent): boolean {

    if (component.eventStarted && component.interpreterJoined) {
      component.unjoinInterpreter();
    }
    return true;
  }
}
