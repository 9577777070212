import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { EventService } from '../../../../../services/event/event.service';
import { FlashMessagesService } from '../../../../../services/util/flash-messages.service';

@Component({
  selector: 'app-meeting-debug',
  templateUrl: './meeting-debug.component.html',
  styleUrls: ['./meeting-debug.component.css']
})
export class MeetingDebugComponent implements OnInit, OnChanges {
  @Input() liveStats: {};
  @Output() playerOptions = new EventEmitter();
  @Output() autoAdjustmentChange = new EventEmitter();
  @Output() autoResynchChange = new EventEmitter();
  initialOptions: any;
  formatTempOptions: string;
  tempStats: any;
  autoAdjust: boolean;
  autoResynch: boolean;
  // LOG LEVEL: 0=NONE, 1=FATAL, 2=ERROR, 3=WARNING, 4=INFO, 5=DEBUG
  debugLogLevel = 1; // Set 'debug.logLevel' to 1 (FATAL) for production
  currentSet = 'Initial';

  constructor(
    public eventServ: EventService,
    private flashMessagesServ: FlashMessagesService
  ) {
    this.autoAdjust = false;
    this.autoResynch = false;

    // Set 'debug.logLevel' to 5 (DEBUG) for development
    if ((environment.name === 'development') || (environment.name === 'local')) {
      this.debugLogLevel = 5;
    }
    this.initialOptions = {
      debug: {
        logLevel: this.debugLogLevel // [3], range: 0 (NONE) to 5 (DEBUG)
      },
      streaming: {
        buffer: {
          stallThreshold: 0.05 // default: 0.3
        },
        lowLatencyEnabled: true, // default: false
        abr: {
          useDefaultABRRules: true, // default: true
          ABRStrategy: 'abrLoLP', // default: "abrDynamic"
          fetchThroughputCalculationMode: 'abrFetchThroughputCalculationMoofParsing' // ["abrFetchThroughputCalculationDownloadedData"]
        },
        delay: {
          liveDelay: 10 // Equivalent in seconds of setLiveDelayFragmentCount.
        },
        liveCatchup: {
          minDrift: 0.05, // default: 0.02, range: 0.0-0.5
          //maxDrift: 12, // default: 12, 0 => not used
          playbackRate: 0.5, // PREV: 0.1, default: 0.5, range: 0-0.5 (0-50%), PREV: 0.1
          latencyThreshold: 60, // PREV: 30, [2 * MAX live delay]
          playbackBufferMin: 1.667, // PREV: 6, default: NaN, 1/60*liveDelay, LoLP only
          mode: 'liveCatchupModeLoLP' // ["liveCatchupModeDefault"]
        }
      }
    };

    this.formatTempOptions = JSON.stringify(this.initialOptions, undefined, 2);
    const dN = new Date();
    this.tempStats = {
      currentVideoPlayerLiveLatency: -1,
      currentAudioPlayerLiveLatency: -1,
      currentVideoPlayerVideoBuffer: -1,
      currentAudioPlayerVideoBuffer: -1,
      currentVideoPlayerAudioBuffer: -1,
      currentAudioPlayerAudioBuffer: -1,
      currentVideoPlayerPlaybackRate: 1,
      currentAudioPlayerPlaybackRate: 1,
      currentVideoPlayerVideoQuality: 'initial',
      currentAudioPlayerVideoQuality: 'initial',
      currentSetLatency: -1,
      currentSetBufferMin: -1,
      dateStart: dN,
      dateReStart: dN,
      dateNow: dN,
      durationAsUTC: 0,
      duration: 0,
      timeAsUTC: 0,
      time: 0
    };
  }

  ngOnInit() {
    // todo - graphing initialization
    this.playerOptions.emit(this.initialOptions);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.liveStats && changes.liveStats.currentValue) {
      this.tempStats.currentVideoPlayerLiveLatency = changes.liveStats.currentValue.currentVideoPlayerLiveLatency || -1;
      this.tempStats.currentAudioPlayerLiveLatency = changes.liveStats.currentValue.currentAudioPlayerLiveLatency || -1;
      this.tempStats.currentVideoPlayerVideoBuffer = changes.liveStats.currentValue.currentVideoPlayerVideoBuffer || -1;
      this.tempStats.currentAudioPlayerVideoBuffer = changes.liveStats.currentValue.currentAudioPlayerVideoBuffer || -1;
      this.tempStats.currentVideoPlayerAudioBuffer = changes.liveStats.currentValue.currentVideoPlayerAudioBuffer || -1;
      this.tempStats.currentAudioPlayerAudioBuffer = changes.liveStats.currentValue.currentAudioPlayerAudioBuffer || -1;
      this.tempStats.currentVideoPlayerPlaybackRate = changes.liveStats.currentValue.currentVideoPlayerPlaybackRate || 1;
      this.tempStats.currentAudioPlayerPlaybackRate = changes.liveStats.currentValue.currentAudioPlayerPlaybackRate || 1;
      this.tempStats.currentVideoPlayerVideoQuality = changes.liveStats.currentValue.currentVideoPlayerVideoQuality || 'initial';
      this.tempStats.currentAudioPlayerVideoQuality = changes.liveStats.currentValue.currentAudioPlayerVideoQuality || 'initial';
      this.tempStats.currentSetLatency = changes.liveStats.currentValue.currentSetLatency || -1;
      this.tempStats.currentSetBufferMin = changes.liveStats.currentValue.currentSetBufferMin || -1;
      this.tempStats.dateStart = changes.liveStats.currentValue.dateStart || -1;
      this.tempStats.dateReStart = changes.liveStats.currentValue.dateReStart || -1;
      this.tempStats.dateNow = new Date();
      this.tempStats.durationAsUTC = changes.liveStats.currentValue.durationAsUTC || -1;
      this.tempStats.duration = changes.liveStats.currentValue.duration || -1;
      this.tempStats.timeAsUTC = changes.liveStats.currentValue.timeAsUTC || -1;
      this.tempStats.time = changes.liveStats.currentValue.time || -1;
    }
  }

  public onSubmit(dashLatencyForm) {
    //if (dashLatencyForm.invalid) {
    //  return;
    //}

    try {
      var jsonStr = this.formatTempOptions.replace(/(\w+:)|(\w+ :)/g, function(s) {
        return '"' + s.substring(0, s.length-1) + '":';
      });
      const options = JSON.parse(
        jsonStr
        .replace(/'/g, '"')
        .replace(/"urn":"mpeg":"dash":"utc":http-"xsdate":2/g, 'urn:mpeg:dash:utc:http-xsdate:2')
        .replace(/"https":\/\//g, 'https:\/\/')
      );
      options.apply = true;
      this.playerOptions.emit(options);
      console.log('onSubmit: options:', options);
    } catch (e) {
      console.error('onSubmit: ERROR: e', e);
      this.flashMessagesServ.error('JSON format invalid');
      this.flashMessagesServ.error(e);
    }
  }

  public showError(input) {
    return input.invalid && input.touched;
  }

  onAutoAdjustmentChange(value) {
    this.autoAdjustmentChange.emit(value);
  }

  onAutoResynchChange(value) {
    this.autoResynchChange.emit(value);
  }

  // seekToLive button action
  seekToLive() {
    const options = { seek: true };
    this.playerOptions.emit(options);
  }

  // Initial settings
  setInitialSettings() {
    this.formatTempOptions = JSON.stringify(this.initialOptions, undefined, 2);
    this.onSubmit(this.formatTempOptions);
    this.currentSet = 'Initial';
  }

  // LoLP
  setLoLPSettings() {
    this.formatTempOptions = JSON.stringify({
      streaming: {
        buffer: {
          stallThreshold: 0.05
        },
        lowLatencyEnabled: true,
        abr: {
          useDefaultABRRules: true,
          ABRStrategy: 'abrLoLP',
          fetchThroughputCalculationMode: 'abrFetchThroughputCalculationMoofParsing'
        },
        delay: {
          liveDelay: 10
        },
        liveCatchup: {
          minDrift: 0.05,
          playbackRate: 0.5,
          latencyThreshold: 60,
          playbackBufferMin: 0.5,
          mode: 'liveCatchupModeLoLP'
        }
      }
    }, undefined, 2);
    this.onSubmit(this.formatTempOptions);
    this.currentSet = 'LoLP';
  }

  // L2A
  setL2ASettings() {
    this.formatTempOptions = JSON.stringify({
      streaming: {
        buffer: {
          stallThreshold: 0.3
        },
        lowLatencyEnabled: true,
        abr: {
          useDefaultABRRules: true,
          ABRStrategy: 'abrL2A'
        },
        delay: {
          liveDelay: 10
        },
        liveCatchup: {
          minDrift: 0.02,
          playbackRate: 0.5,
          latencyThreshold: 60
        }
      }
    }, undefined, 2);
    this.onSubmit(this.formatTempOptions);
    this.currentSet = 'L2A';
  }

  // AAST very low latency:
  setAASTSettings() {
    this.formatTempOptions = JSON.stringify({
      streaming: {
        buffer: {
          stallThreshold: 0.3
        },
        lowLatencyEnabled: true,
        abr: {
          ABRStrategy: 'abrThroughput',
          fetchThroughputCalculationMode: 'abrFetchThroughputCalculationAAST'
        },
        delay: {
          liveDelay: 10
        },
        liveCatchup: {
          minDrift: 0.02,
          playbackRate: 0.5,
          latencyThreshold: 60
        }
      }
    }, undefined, 2);
    this.onSubmit(this.formatTempOptions);
    this.currentSet = 'AAST';
  }

  // dash.js Live settings with and without catchup enabled
  setDashLiveSettings(catchup) {
    const set = {
      streaming: {
        buffer: {
          stallThreshold: 0.3
        },
        lowLatencyEnabled: true,
        delay: {
          liveDelay: 10
        },
        liveCatchup: {
          minDrift: 0.02,
          playbackRate: 0.5,
          latencyThreshold: 60,
          mode: 'liveCatchupModeDefault',
          enabled: catchup || false
        }
      }
    };

    this.formatTempOptions = JSON.stringify(set, undefined, 2);
    this.onSubmit(this.formatTempOptions);
    this.currentSet = catchup ? 'LiveCatchup' : 'Live';
  }

  // Synchronized live playback:
  setSynchSettings() {
    this.formatTempOptions = JSON.stringify({
      streaming: {
        delay: {
          liveDelay: 10
        },
        liveCatchup: {
          enabled: true
        }
      }
    }, undefined, 2);
    this.onSubmit(this.formatTempOptions);
    this.currentSet = 'Synch';
  }

  // dash.js Full Settings
  setFullSettings() {
    this.formatTempOptions = JSON.stringify({
      debug: {
        logLevel: 3,
        dispatchEvent: false
      },
      streaming: {
        abandonLoadTimeout: 10000,
        wallclockTimeUpdateInterval: 100,
        lowLatencyEnabled: false,
        lowLatencyEnabledByManifest: true,
        manifestUpdateRetryInterval: 100,
        cacheInitSegments: false,
        eventControllerRefreshDelay: 150,
        capabilities: {
          filterUnsupportedEssentialProperties: true,
          useMediaCapabilitiesApi: false
        },
        timeShiftBuffer: {
          calcFromSegmentTimeline: false,
          fallbackToSegmentTimeline: true
        },
        metrics: {
          maxListDepth: 100
        },
        delay: {
          liveDelayFragmentCount: NaN,
          liveDelay: NaN,
          useSuggestedPresentationDelay: true,
          applyServiceDescription: true
        },
        protection: {
          keepProtectionMediaKeys: false,
          ignoreEmeEncryptedEvent: false
        },
        buffer: {
          enableSeekDecorrelationFix: false,
          fastSwitchEnabled: true,
          flushBufferAtTrackSwitch: false,
          reuseExistingSourceBuffers: true,
          bufferPruningInterval: 10,
          bufferToKeep: 20,
          bufferTimeAtTopQuality: 30,
          bufferTimeAtTopQualityLongForm: 60,
          initialBufferLevel: NaN,
          stableBufferTime: 12,
          longFormContentDurationThreshold: 600,
          stallThreshold: 0.3,
          useAppendWindow: true,
          setStallState: true
        },
        gaps: {
          jumpGaps: true,
          jumpLargeGaps: true,
          smallGapLimit: 1.5,
          threshold: 0.3,
          enableSeekFix: true
        },
        utcSynchronization: {
          enabled: true,
          useManifestDateHeaderTimeSource: true,
          backgroundAttempts: 2,
          timeBetweenSyncAttempts: 30,
          maximumTimeBetweenSyncAttempts: 600,
          minimumTimeBetweenSyncAttempts: 2,
          timeBetweenSyncAttemptsAdjustmentFactor: 2,
          maximumAllowedDrift: 100,
          enableBackgroundSyncAfterSegmentDownloadError: true,
          defaultTimingSource: {
            scheme: 'urn:mpeg:dash:utc:http-xsdate:2014',
            value: 'https://' + this.eventServ.getCurrentEvent()._id + '.ablioaudience.com/iso'
          }
        },
        scheduling: {
          defaultTimeout: 500,
          lowLatencyTimeout: 0,
          scheduleWhilePaused: true
        },
        text: {
          defaultEnabled: true
        },
        liveCatchup: {
          minDrift: 0.02,
          maxDrift: 12,
          playbackRate: 0.5,
          latencyThreshold: 60,
          playbackBufferMin: 0.5,
          enabled: false,
          mode: 'liveCatchupModeDefault'
        },
        lastBitrateCachingInfo: {
          enabled: true,
          ttl: 360000
        },
        lastMediaSettingsCachingInfo: {
          enabled: true,
          ttl: 360000
        },
        cacheLoadThresholds: {
          video: 50,
          audio: 5
        },
        trackSwitchMode: {
          audio: 'alwaysReplace',
          video: 'neverReplace'
        },
        selectionModeForInitialTrack: 'highestSelectionPriority',
        fragmentRequestTimeout: 0,
        retryIntervals: {
          MPD: 500,
          XLinkExpansion: 500,
          MediaSegment: 1000,
          InitializationSegment: 1000,
          BitstreamSwitchingSegment: 1000,
          IndexSegment: 1000,
          FragmentInfoSegment: 1000,
          license: 1000,
          other: 1000,
          lowLatencyReductionFactor: 10
        },
        retryAttempts: {
          MPD: 3,
          XLinkExpansion: 1,
          MediaSegment: 3,
          InitializationSegment: 3,
          BitstreamSwitchingSegment: 3,
          IndexSegment: 3,
          FragmentInfoSegment: 3,
          license: 3,
          other: 3,
          lowLatencyMultiplyFactor: 5
        },
        abr: {
          movingAverageMethod: 'slidingWindow',
          ABRStrategy: 'abrDynamic',
          additionalAbrRules: {
            insufficientBufferRule: true,
            switchHistoryRule: true,
            droppedFramesRule: true,
            abandonRequestsRule: false
          },
          bandwidthSafetyFactor: 0.9,
          useDefaultABRRules: true,
          useDeadTimeLatency: true,
          limitBitrateByPortal: false,
          usePixelRatioInLimitBitrateByPortal: false,
          maxBitrate: {
            audio: -1,
            video: -1
          },
          minBitrate: {
            audio: -1,
            video: -1
          },
          maxRepresentationRatio: {
            audio: 1,
            video: 1
          },
          initialBitrate: {
            audio: -1,
            video: -1
          },
          initialRepresentationRatio: {
            audio: -1,
            video: -1
          },
          autoSwitchBitrate: {
            audio: true,
            video: true
          },
          fetchThroughputCalculationMode: 'abrFetchThroughputCalculationMoofParsing'
        },
        cmcd: {
          enabled: false,
          sid: null,
          cid: null,
          rtp: null,
          rtpSafetyFactor: 5,
          mode: 'query'
        }
      },
      errors: {
        recoverAttempts: {
          mediaErrorDecode: 5
        }
      }
    }, undefined, 2);
    this.onSubmit(this.formatTempOptions);
    this.currentSet = 'Full';
  }
}
