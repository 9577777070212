import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth/auth.service';
import { FlashMessagesService } from '../../../services/util/flash-messages.service';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.css']
})
export class PasswordForgotComponent implements OnInit {
  username: string;
  success: boolean;
  error: boolean;

  constructor(
    private router: Router,
    private authServ: AuthService,
    private flashMessagesServ: FlashMessagesService
  ) {}

  ngOnInit() {
    this.username = '';
  }

  onUsernameChange() {
    if (this.error) this.error = false;
  }

  showError(input): boolean {
    return (input.invalid && input.touched);
  }

  public onSubmit() {
    if (this.username) {
      this.authServ.passwordforgot(this.username, (err, data) => {
        //console.log(data || 'null');
        if (err && (err.status !== 200)) {
          console.log(err);
          this.error = true;

          if (err.error.message) {
            if (err.error.message === 'Invalid data') {
              return this.flashMessagesServ.error('Error: invalid data.');
            } else if (err.error.message === 'An email with the instructions has been already sent to you.') {
              this.success = true;
              return this.flashMessagesServ.info('An email with the instructions has been already sent to you.');
            }
          }

          return this.flashMessagesServ.error('Error: ' + err.error.message);
        }

        this.success = true;
      });
    }
  }
}
