import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../models/User';
import { EventService } from '../event/event.service';
import { UserService } from '../user/user.service';
import { OsService } from '../util/os.service';

@Injectable({
  providedIn: 'root'
})
export class SocketsService implements OnDestroy {
  ws: any;
  user: User;
  socketMessageReceive: Subject<any> = new BehaviorSubject<any>(null);
  ready: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private subscriptions = new Subscription();
  private reconnecting: boolean;
  private lastPingTimeout;
  private reconnectAttempt = 1;

  constructor(
    private router: Router,
    private eventServ: EventService,
    private userServ: UserService,
    private osServ: OsService
  ) {
    this.subscriptions.add(this.userServ.userChange.subscribe((user) => {
      if (!user || !user._id || (_.get(this, 'user._id') === user._id)) {
        return;
      }
      this.user = user;
      this.connectToServer();
    }));
  }

  sendMessage(message) {
    if (!this.ws) {
      console.log('No WebSocket connection');
      return;
    }

    if (this.ws.readyState === WebSocket.OPEN) {
      const jsonMessage = JSON.stringify(message);
      this.ws.send(jsonMessage);
    } else {
      console.log('ERROR: ws.readyState is: ' + this.ws.readyState + '. Not OPEN.');
    }
  }

  joinGroup(groupId) {
    this.sendMessage({ messageId: 'joinGroup', groupId: groupId, from: this.user._id, fromName: this.user.username });
  }

  private connectToServer() {
    if (!this.user) {
      return;
    }
    this.clearWs();
    this.ws = new WebSocket(`wss://${ environment.wsHostname }:8491`);
    this.ws.onerror = (error) => {
      console.log('WebSocket error');
      console.log(error);
      this.reconnecting = false;
      this.reconnectToServer();
    };
    this.ws.onopen = () => {
      this.ready.next(true);
      this.reconnecting = false;
      this.reconnectAttempt = 1;
      console.log('WebSocket connection established');
      this.sendMessage({
        messageId: 'connectingToServer',
        userId: this.user._id,
        name: this.user.username,
        macOSUser: this.osServ.isMac(),
        isInterpreter: this.user.isInterpreter(),
        eventId: this.eventServ.getCurrentEvent() ? this.eventServ.getCurrentEvent()._id : null
      });
    };
    this.ws.onclose = () => {
      this.ws.removeAllListeners();
      console.log('WebSocket connection closed');
      this.reconnectToServer();
    };

    this.ws.onmessage = (message: any) => {
      const parsedMessage = JSON.parse(message.data);
      if (parsedMessage.messageId === 'ping') {
        clearTimeout(this.lastPingTimeout);
        this.sendMessage({ messageId: 'pong' });
        this.lastPingTimeout = setTimeout(() => {
          // didn't receive ping for more than 15 seconds (server ping), client or server dead, reload page
          this.setReconnectingTrue();
          location.reload();
        }, 15000);
        return;
      }
      if (parsedMessage.messageId === 'eventRestarted') {
        // If is interpreter of event, reload page
        const event = this.eventServ.getCurrentEvent();
        if (event && (event._id === parsedMessage.eventId) && this.user.isInterpreter()) {
          location.reload();
        }
      }
      if (parsedMessage.messageId === 'moveToDashboard') {
        // If is interpreter of event, move to dashboard and reload page
        if (this.user.isInterpreter()) {
          this.router.navigate(['/dashboard/interpreter/events'], { queryParams: { forceReload: true } });
        }
      }
      this.socketMessageReceive.next(parsedMessage);
    };
  }

  private setReconnectingTrue() {
    this.ready.next(false);
    this.socketMessageReceive.next(null);
    this.reconnecting = true;
  }

  private reconnectToServer() {
    if (this.reconnecting) {
      return;
    }
    this.setReconnectingTrue();
    setTimeout(() => {
      this.reconnectAttempt++;
      console.log('Reconnecting WebSocket to server');
      this.connectToServer();
    }, 500 * this.reconnectAttempt);
  }

  private clearWs() {
    clearTimeout(this.lastPingTimeout);
    if (this.ws) {
      this.ws.removeAllListeners();
      this.ws.close();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
