// Core imports
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// Application imports
import { WebRtcPeer } from '../../../../libs/WebRtcPeer';
import { Event, EventStatus } from '../../../../models/Event';
import { User } from '../../../../models/User';
import { EventService } from '../../../../services/event/event.service';
import { KurentoService } from '../../../../services/kurento/kurento.service';
import { UserService } from '../../../../services/user/user.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';
import { VideoElementService } from '../../../../services/util/video-element.service';

declare var console: any;

@Component({
  selector: 'app-speaker',
  templateUrl: './speaker.component.html',
  styleUrls: ['./speaker.component.css']
})
export class KBroadcastComponent implements OnInit, OnDestroy {
  // eventId used to insert the component in another page passing the id as parameter
  @Input() eventId: string;
  // test is true when the event must be started on test mode, false for live mode
  @Input() test: false;

  sub: any;
  subscriptions = new Subscription();

  webRtcPeerSend: WebRtcPeer;
  webRtcPeerReceive: WebRtcPeer;

  user: User;
  statsMap: Array<Object> = [];
  eventStarted: boolean;
  restartingEvent: boolean;
  eventInit: boolean;
  capturingScreen: boolean;
  programStarted: boolean;
  remoteDisabled: boolean = false;
  mode: string;
  remoteURL: string;
  captureURL: string;
  channelURL: SafeResourceUrl;
  startConferenceText = 'Start conference';
  startConferenceMsg = '';

  eCode: string;
  eventCodeEntered: boolean;
  submitted: boolean;
  eId: string;
  eData: Event; // NOT USED

  video: any;
  videoElement: any;
  audioInputSelect: any;
  audioOutputSelect: any;
  videoSelect: any;
  selectors: any;

  private readonly startingMediaServerMsg = '  Starting the media server. It will require approximately 3 minutes, please wait…';
  private readonly restartingMediaServerMsg = '  Restarting the media server. It will require approximately 30 seconds, please wait…';

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private eventServ: EventService,
    private kurento: KurentoService,
    private userServ: UserService,
    private flashMessagesServ: FlashMessagesService,
    private videoElementServ: VideoElementService
  ) {
    this.subscriptions.add(KurentoService.statsMapChange.subscribe((value) => {
      //this.statsMap = value;
      //console.log(this.statsMap);
      //this.speakerStreamingToggle();
    }));

    this.subscriptions.add(KurentoService.streamWorkingChange.subscribe((value) => {
      if (value) {
        // TODO green light indicator
        return;
      } else {
        this.flashMessagesServ.error('Cannot start program. Check source resolution (suggested is up to 1280x720) and try again…');
        this.stop();
      }
    }));
  }

  ngOnInit() {
    KurentoService.checkEventStatus();
    if (this.eventServ.getCurrentEvent()) {
      if (this.eventServ.getCurrentEvent().status === EventStatus.STARTING) {
        this.eventInit = true;
      }

      // Disable remote
      if (this.eventServ.getCurrentEvent().audience.webPortal.available) {
        this.remoteDisabled = true;
      }
    }

    this.subscriptions.add(KurentoService.eventStartedChange.subscribe((value) => {
      if (!this.restartingEvent) {
        if (!this.eventStarted && value) {
            this.eventInit = false;
          } else if (this.eventStarted && !value) {
            this.stopEventResponse();
          }
        this.eventStarted = value;
      }
      if (KurentoService.eventStatus.starting || KurentoService.eventStatus.restarting) {
        this.eventInit = true;
        this.startConferenceMsg = KurentoService.eventStatus.starting ? this.startingMediaServerMsg : this.restartingMediaServerMsg;
      }

      // Loads user data
      this.subscriptions.add(this.userServ.userChange.subscribe((user) => {
          if (user) {
            // Registers the interpreter in the proper language pair
            this.user = user;
            KurentoService.speakerJoin(this.eventId, user._id);
          }
      }));
    }));

    this.subscriptions.add(KurentoService.restartEventRejectedChange.subscribe((error) => {
      const options = {
        showCloseBtn: true,
        timeout: 15000
      }
      this.flashMessagesServ.error('Restart event failed. Please contact HelpDesk by using the instant chatting widget button available at the bottom right corner', options);
      let msg = error.message;
      if (typeof msg === 'object') {
        msg = msg.message;
      }

      if (msg) {
        this.flashMessagesServ.error(msg, options);
      }
    }));

    this.subscriptions.add(KurentoService.eventRestartedByServerChange.subscribe((restarted) => {
      if (restarted) {
        this.setVariablesForRestartingEvent();
      }
    }));

    //this.flashMessagesServ.warning('ngOnInit…');
    //let console = new Console();
    //this.video = document.getElementById('video-speaker');

    this.sub = this.route.queryParams.subscribe(params => {
      if (params['event'] || this.eventId) {
        this.eventCodeEntered = true;
      }

      // sets the event param in the proper service attribute or use the default event
      KurentoService.eventId = params['event'] || this.eventId || '5a8f837473e4f408b03eda96';

      this.channelURL = KurentoService.getProviderURL(params, this.sanitizer);
    });

    if (this.eventCodeEntered) {
      KurentoService.startWsIfNotRunning();

      this.videoElement = document.getElementById('video-speaker');
      this.audioInputSelect = document.getElementById('audioSource');
      this.audioOutputSelect = document.getElementById('audioOutput');
      this.videoSelect = document.getElementById('videoSource');
      this.selectors = [this.audioInputSelect, this.audioOutputSelect, this.videoSelect];

      this.audioOutputSelect.disabled = !('sinkId' in HTMLMediaElement.prototype);

      // Remote source default values
      this.mode = 'local';
      this.remoteURL = 'https://webrtc.github.io/samples/src/video/chrome.webm';
      this.captureURL = '';

      //console.warn(navigator.mediaDevices.enumerateDevices());
      this.loadSources();
    } else {
      this.eCode = '';
    }

    // Hide top header and navbar, footer, and Intercom widget
    document.getElementById('topHeader').classList.add('d-none');
    document.getElementById('topNavbar').classList.add('d-none');
    document.getElementById('f').classList.add('d-none');
    /* TODO: Shut down or hide Intercom widget
    let nRetries = 0;
    const hIWI = setInterval(() => {
      nRetries++;
      let stopInt = false;
      if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
        for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
          document.getElementsByClassName('intercom-lightweight-app')[c].classList.add('d-none');
          stopInt = true;
        }
      }
      if (document.getElementById('intercom-container') !== null) {
        document.getElementById('intercom-container').classList.add('d-none');
        stopInt = true;
      }
      if (stopInt || (nRetries > 3)) {
        clearInterval(hIWI);
      }
    }, 7500);
    */

    navigator.mediaDevices.addEventListener('devicechange', this.loadSources);
  }

  // Show eventCode errors
  public showError(input): boolean {
    return (input.invalid && (input.touched || this.submitted));
  }

  // Submit the event code and handle the response
  public onSubmit(enterEventCodeForm) {
    this.submitted = true;

    // Form validation
    if (enterEventCodeForm.invalid) {
      return;
    }

    // console.log(this.eCode);
    //this.eId = this.route.snapshot.paramMap.get('event');
    this.eventServ.getByCode(this.eCode, (err, data) => {
      if (err) {
        console.log(err);
      }

      this.eId = '5a8f837473e4f408b03eda96';
      if (data && data._id) {
        this.eId = data._id.toString();
        this.eData = data; // NOT USED
      }

      // TODO: To use the two lines below (and remove the 3rd one) we need to move Kurento stuff outside ngOnInit
      //this.eventCodeEntered = true;
      //this.router.navigate(['/speaker', { event: this.eId }]);
      location.replace('/speaker?event=' + this.eId);
    });
  }

  changeSource() {
    const audioSource = this.audioInputSelect.value;
    const videoSource = this.videoSelect.value;
    if (videoSource.includes('screen')) {
      this.capturingScreen = true;
    } else {
      if (this.capturingScreen === true) this.capturingScreen = false;
      const constraints = {
        audio: audioSource ? { deviceId: audioSource ? { exact: audioSource } : undefined } : false,
        video: videoSource ? { deviceId: videoSource ? { exact: videoSource } : undefined } : false
      };

      /*
      navigator.mediaDevices.getUserMedia(constraints);
      .then((stream) => { this.gotStream(stream) });
      .then((deviceInfo) => { this.gotDevices(deviceInfo) })
      .catch((error) => { this.handleError(error) });
      */
    }

    /*
    navigator.mediaDevices.enumerateDevices()
    .then((deviceInfo) => { this.gotDevices(deviceInfo) })
    .catch((error) => { this.handleError(error) });
    */
  }

  changeMode(mode: string) {
    this.mode = mode;
    console.log(mode);
    console.log(this.remoteURL);
    //this.loadSources();
  }

  private disposeWebRtcPeers() {
    KurentoService.disposeWebRtcPeer(this.webRtcPeerSend);
    KurentoService.disposeWebRtcPeer(this.webRtcPeerReceive);
  }

  presenter() {
    //KurentoService.startWsIfNotRunning();

    //if (!KurentoService.webRtcPeer) {
    this.videoElementServ.showSpinner(this.videoElement);

    const audioSource = this.audioInputSelect.value;
    const videoSource = this.videoSelect.value;

    if (!videoSource) {
      this.videoElementServ.hideSpinner(this.videoElement);
    }

    console.log('source:' + videoSource);

    let constraints: any = {
      audio: audioSource ? { deviceId: audioSource ? { exact: audioSource } : undefined } : false,
      video: videoSource ? { deviceId: videoSource ? { exact: videoSource } : undefined } : false
    };

    if (this.mode === 'remote' || videoSource.includes('screen')) {
      constraints = {
        video: true,
        audio: true
      }
    }

    KurentoService.speakerConstraints = {
      audio: constraints.audio ? true : false,
      video: constraints.video ? true : false
    };

    console.log('constraints');
    console.log(constraints);

    const options: any = {
      localVideo: null,
      remoteVideo: null,
      onicecandidate: KurentoService.onIceCandidate,
      mediaConstraints: constraints,
      sendSource: 'webcam'
      /*
      sendSource: 'screen'
      sendSource: 'webcam',
      dataChannelConfig: {
        id : KurentoService.eventId,
        onmessage : onMessage,
        onopen : onOpen,
        onclose : onClosed,
        onbufferedamountlow : onbufferedamountlow,
        onerror : onerror
      }
      */
    };

    KurentoService.mode = this.mode;
    KurentoService.remoteURL = this.remoteURL;
    if (videoSource.includes('screen')) {
      options.sendSource = 'screen';
    }

    this.disposeWebRtcPeers();
    // Starts local or remote streaming
    if (this.mode === 'local' && (constraints.audio || constraints.video)) {
      if (options.sendSource === 'screen') {
        console.warn('SCREEN CAPTURE MODE');
        let displayMediaOptions = {
          audio: {
            echoCancellation: false,
            autoGainControl: false,
            noiseSuppression: false
          },
          video: {
            width: { ideal: 1280, max: 1280 },
            height: { ideal: 720 },
            aspectRatio: { ideal: 1.7777777778 },
            frameRate: { ideal: 30 }
          }
        };
        options.mediaConstraints = displayMediaOptions;
        //@ts-ignore
        navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
        .then(stream => {
          options.videoStream = stream;
          options.localVideo = this.videoElement;
          KurentoService.webRtcPeerSpeaker = this.webRtcPeerSend = WebRtcPeer.Sendonly(options, function (error: any) {
            if (error) {
              console.warn(error);
              throw new Error(error);
            }
            this.generateOffer(KurentoService.onOfferPresenter);
          });
          this.programStarted = true;
        })
        .catch(err => {
          this.flashMessagesServ.error('Program not started properly…. Please STOP and START program again.');
          console.warn(err)
        });
      } else {
        console.warn('LOCAL MODE');
        options.localVideo = this.videoElement;
        if (options.mediaConstraints) {
          if (options.mediaConstraints.audio) {
            options.mediaConstraints.audio.echoCancellation = false;
            options.mediaConstraints.audio.autoGainControl = false;
            options.mediaConstraints.audio.noiseSuppression = false;
          }
          if (options.mediaConstraints.video) {
            options.mediaConstraints.video.width = { ideal: 1280 };
            options.mediaConstraints.video.height = { ideal: 720 };
            options.mediaConstraints.video.aspectRatio = { ideal: 1.7777777778 };
            options.mediaConstraints.video.frameRate = { ideal: 30 };
          }
        }
        KurentoService.webRtcPeerSpeaker = this.webRtcPeerSend = WebRtcPeer.Sendonly(options, function (error: any) {
          if (error) {
            throw new Error(error);
          }
          this.generateOffer(KurentoService.onOfferPresenter);
        });
        this.programStarted = true;
      }
    } else if (this.mode === 'remote') {
      console.warn('REMOTE MODE');
      options.remoteVideo = this.videoElement;
      KurentoService.webRtcPeerSpeaker = this.webRtcPeerReceive = WebRtcPeer.Recvonly(options, function (error) {
        if (error) {
          throw new Error(error);
        }

        this.generateOffer(KurentoService.onOfferPresenter);
      });
      this.programStarted = true;
    } else {
      this.flashMessagesServ.error('Cannot start program. Check settings on the right side and try again…');
    }
    //} else {
    //  console.log('webRtcPeer already existing');
    //}
  }

  stop() {
    this.dispose();
    KurentoService.onStop();
    this.programStarted = false;
    if (this.capturingScreen === true) this.capturingScreen = false;
    this.loadSources();
  }

  startEvent() {
    KurentoService.startEvent();
    this.eventInit = true;
    this.startConferenceText = 'Starting conference…';
    this.startConferenceMsg = this.startingMediaServerMsg;
  }

  stopEvent() {
    KurentoService.stopEvent();
  }

  private setVariablesForRestartingEvent() {
    this.restartingEvent = true;
    setTimeout (() => {
      // used for stop listening event started changes for 5 seconds to avoid status confusion, event will be restarting
      this.restartingEvent = false;
    }, 5000);
    this.stop();
    // Event restarted, notify other components (audience component)
    KurentoService.eventRestartedChange.next(true);
    this.eventStarted = false;
    this.eventInit = true;
    this.startConferenceText = 'Restarting conference…';
    this.startConferenceMsg = this.restartingMediaServerMsg;
  }

  restartEvent() {
    this.setVariablesForRestartingEvent();
    // send message to server to restart event
    KurentoService.restartEvent();
  }

  stopEventResponse() {
    this.stop();
    this.startConferenceText = 'Start conference';
    this.eventInit = false;
  }

  /*
  isEventStarted() {
    return this.eventStarted;
  }
  */

  dispose() {
    this.disposeWebRtcPeers();
    this.videoElementServ.hideSpinner(this.videoElement);
    console.log('WebRTC peer disposed!');
  }

  /*
  stats() {
    if (KurentoService.webRtcPeer) {
      const peerConnection = KurentoService.webRtcPeer.peerConnection;

      peerConnection.getStats((stats) => {
        console.log('STATS:');

        const results = stats.result();
        for (let i = 0; i < results.length; i++) {
          const res = results[i];
          if (res.type !== 'ssrc') {
            continue;
          }

          // Publish it to be compliant with W3C stats draft
          this.statsMap[i] = {
            timeStamp: res.timestamp,
            // StreamStats below
            associateStatsId: res.id,
            codecId: '--',
            firCount: res.stat('googFirsReceived'),
            isRemote: false,
            mediaTrackId: res.stat('googTrackId'),
            nackCount: res.stat('googNacksReceived'),
            pliCount: res.stat('googPlisReceived'),
            sliCount: 0,
            ssrc: res.stat('ssrc'),
            transportId: res.stat('transportId'),
            // Specific outbound below
            bytesSent: res.stat('bytesSent'),
            packetsSent: res.stat('packetsSent'),
            roundTripTime: res.stat('googRtt'),
            packetsLost: res.stat('packetsLost'),
            targetBitrate: '??',
            remb: '??'
          };
        }
        console.log(this.statsMap);
        //return callback('Error: could not find ssrc type on track stats', null);
      }, this.statsMap);
    }
    // Webrtc endpoints stats
    KurentoService.endpointStats(KurentoService.eventId, 'speaker', '', 0);
  }
  */

  openCaptureWindow() {
    //Popup window for capture
    try {
      new URL(this.captureURL);
      window.open(
        this.captureURL,
        'capturewin',
        'top=0,left=' + ((screen.width - 1280) > 0 ? screen.width - 1280 : 0) + ',width=1280,height=724'
      );
    } catch {
      return this.flashMessagesServ.error('Wrong URL format');
    }
  }

  gotDevices(deviceInfos) {
    console.warn(deviceInfos);
    // Handles being called several times to update labels. Preserve values.
    const values = this.selectors.map(select => select.value);
    this.selectors.forEach(select => {
      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });

    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${ this.audioInputSelect.length + 1 }`;
        this.audioInputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || `speaker ${ this.audioOutputSelect.length + 1 }`;
        this.audioOutputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${ this.videoSelect.length + 1 }`;
        this.videoSelect.appendChild(option);
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    this.selectors.forEach((select, selectorIndex) => {
      if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
        select.value = values[selectorIndex];
      }
    });

    // Screen capture option creation
    const screenVideo = document.createElement('option');
    screenVideo.value = 'screenvid';
    screenVideo.text = 'Ablio Capture';

    // None option creation
    const optionVideo = document.createElement('option');
    optionVideo.value = '';
    optionVideo.text = 'None';

    const optionAudio = document.createElement('option');
    optionAudio.value = '';
    optionAudio.text = 'None';

    this.videoSelect.appendChild(screenVideo);

    this.videoSelect.appendChild(optionVideo);
    this.audioInputSelect.appendChild(optionAudio);
  }

  loadSources() {
    navigator.mediaDevices.enumerateDevices()
    .then((deviceInfo) => {
      this.gotDevices(deviceInfo);
      this.changeSource();
    })
    .catch((error) => {
      console.error(error);
    });
  }

  // Attach audio output device to video element using device/sink ID.
  attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${ sinkId }`);
        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${ error }`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          this.audioOutputSelect.selectedIndex = 0;
        });
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }

  changeAudioDestination() {
    const audioDestination = this.audioOutputSelect.value;
    this.attachSinkId(this.videoElement, audioDestination);
  }

  gotStream(stream) {
    (<any>window).stream = stream; // make stream available to console
    this.videoElement.srcObject = stream;
    // Refresh button list in case labels have become available
    return navigator.mediaDevices.enumerateDevices();
  }

  handleError(error) {
    console.log('navigator.getUserMedia error: ', error);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.stop();
  }

  ngOnDestroy() {
    this.stop();

    // Show top header and navbar, footer, and Intercom widget again
    document.getElementById('topHeader').classList.remove('d-none');
    document.getElementById('topNavbar').classList.remove('d-none');
    document.getElementById('f').classList.remove('d-none');
    /* TODO: Start up or show Intercom widget again
    if (document.getElementsByClassName('intercom-lightweight-app').length > 0) {
      for (let c = 0; c < document.getElementsByClassName('intercom-lightweight-app').length; c++) {
        document.getElementsByClassName('intercom-lightweight-app')[c].classList.remove('d-none');
      }
    }
    if (document.getElementById('intercom-container') !== null) {
      document.getElementById('intercom-container').classList.remove('d-none');
    }
    */

    this.subscriptions.unsubscribe();
  }
}
