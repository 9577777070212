import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Ng2CompleterModule } from 'ng2-completer';
import { FileUploadModule } from 'ng2-file-upload';

// Components
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LangSelectorComponent } from './components/lang-selector/lang-selector.component';
import { ModalComponent } from './components/modal/modal.component';
//import { NoconnectionModalComponent } from './components/noconnection-modal/noconnection-modal.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { WorldAreasSelectorComponent } from './components/world-areas-selector/world-areas-selector.component';

// Directives
import { TitleCaseDirective } from './directives/title-case.directive';
import { UppercaseTextDirective } from './directives/uppercase-text.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    Ng2CompleterModule,
    FileUploadModule
  ],
  declarations: [
    CountrySelectorComponent,
    FileUploadComponent,
    LangSelectorComponent,
    ModalComponent,
    //NoconnectionModalComponent,
    PhoneInputComponent,
    WorldAreasSelectorComponent,

    // Directives
    TitleCaseDirective,
    UppercaseTextDirective
  ],
  exports: [
    CountrySelectorComponent,
    FileUploadComponent,
    LangSelectorComponent,
    ModalComponent,
    //NoconnectionModalComponent,
    PhoneInputComponent,
    WorldAreasSelectorComponent,

    // Directives
    TitleCaseDirective,
    UppercaseTextDirective
  ]
})
export class UtilModule { }
