import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import {CountryService} from '../../../../services/util/country.service';

@Component({
  selector: 'app-world-areas-selector',
  templateUrl: './world-areas-selector.component.html',
  styleUrls: ['./world-areas-selector.component.css']
})
export class WorldAreasSelectorComponent implements OnInit {
  @Input() inputAreas: any;
  continents: Array<any>;
  worldwide: boolean;
  @Input() langCode: string;

  @Output() area = new EventEmitter();

  constructor(private countryServ: CountryService) {
  }

  ngOnInit() {
    // Load all areas
    const continents = this.countryServ.getContinents();
    const countries = this.countryServ.getCountries();
    const regions = this.countryServ.getRegions();
    for (let i = 0; i < continents.length; i++) {
      continents[i].countries = [];
      // Set all countries covered by default for each continent
      continents[i].all = true;
      for (let j = 0; j < countries.length; j++) {
        if (countries[j].continent === continents[i].code) {
          // Set regions for country
          if (regions[countries[j].code]) {
            countries[j].regions = regions[countries[j].code];
            countries[j].all = true;
          }
          continents[i].countries.push(countries[j]);
        }
      }
    }

    if ((!this.inputAreas) || (this.inputAreas.worldwide === undefined) || (this.inputAreas.worldwide === null) || this.inputAreas.worldwide) {
      this.worldwide = true;
    } else {
      // Mark already selected areas existent in inputAreas
      this.worldwide = false;
      for (let x = 0; x < this.inputAreas.continents.length; x++) {
        for (let i = 0; i < continents.length; i++) {
          if (continents[i].code === this.inputAreas.continents[x].code) {
            // Continent found
            continents[i].all = !!this.inputAreas.continents[x].all;
            continents[i].selected = this.inputAreas.continents[x].selected;
            for (let y = 0; ((this.inputAreas.continents[x].countries) && (y < this.inputAreas.continents[x].countries.length)); y++) {
              for (let j = 0; j < continents[i].countries.length; j++) {
                if (continents[i].countries[j].code === this.inputAreas.continents[x].countries[y].code) {
                  // Country found
                  continents[i].countries[j].all = !!this.inputAreas.continents[x].countries[y].all;
                  continents[i].countries[j].selected = this.inputAreas.continents[x].countries[y].selected;
                  for (let z = 0; ((this.inputAreas.continents[x].countries[y].regions) && (z < this.inputAreas.continents[x].countries[y].regions.length)); z++) {
                    for (let k = 0; k < continents[i].countries[j].regions.length; k++) {
                      if (continents[i].countries[j].regions[k].code === this.inputAreas.continents[x].countries[y].regions[z].code) {
                        // Region found
                        continents[i].countries[j].regions[k].all = !!this.inputAreas.continents[x].countries[y].regions[z].all;
                        continents[i].countries[j].regions[k].selected = this.inputAreas.continents[x].countries[y].regions[z].selected;
                        break;
                      }
                    }
                  }
                  break;
                }
              }
            }
            break;
          }
        }
      }
    }
    this.continents = cloneDeep(continents);
  }

  changeSelectedArea() {
    const selected = {
      worldwide: this.worldwide,
      continents: []
    };

    if (selected.worldwide) {
      return this.area.emit(selected);
    } else {
      selected.continents = cloneDeep(this.continents);
    }

    for (let i = 0; i < selected.continents.length; i++) {
      if (!selected.continents[i].selected) {
        // if continent not selected, delete it and continue with other continents
        selected.continents.splice(i, 1);
        i--;
        continue;
      }
      if (!selected.continents[i].all) {
        // if not all countries are selected, keep only selected countries list
        for (let j = 0; j < selected.continents[i].countries.length; j++) {
          if (!selected.continents[i].countries[j].selected) {
            // if country not selected, delete it and continue with other countries
            selected.continents[i].countries.splice(j, 1);
            j--;
            continue;
          }
          if (!selected.continents[i].countries[j].all && selected.continents[i].countries[j].regions) {
            // if not all regions are selected, keep only selected regions list
            for (let k = 0; k < selected.continents[i].countries[j].regions.length; k++) {
              // if region is not selected, delete it
              if (!selected.continents[i].countries[j].regions[k].selected) {
                selected.continents[i].countries[j].regions.splice(k, 1);
                k--;
              }
            }
          } else if (selected.continents[i].countries[j].regions) {
            // if all regions are selected, delete regions list because is not needed
            delete selected.continents[i].countries[j].regions;
          } else {
            selected.continents[i].countries[j].all = true;
          }
          if (selected.continents[i].countries[j].seleccted && !selected.continents[i].countries[j].regions) {
            selected.continents[i].countries[j].all = true;
          }
        }
      } else {
        // if all countries are selected, delete countries list because is not needed
        delete selected.continents[i].countries;
      }
    }
    this.area.emit(selected);
  }
}
