import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-meeting-controlbar',
  templateUrl: './meeting-controlbar.component.html',
  styleUrls: ['./meeting-controlbar.component.css']
})
export class MeetingControlbarComponent implements OnInit {
  @ViewChild('langNameDisplay') langNameDisplay: ElementRef;
  @Input() langsFromParent: any;
  @Input() originalMuted: boolean;
  @Input() translationMuted: boolean;
  @Input() changedVolumes: { original: number, translation: number };
  @Input() firstLanguageSwitch: boolean;
  @Input() currLangOnAir: boolean;
  @Input() embed: boolean;
  @Output() playerAction: EventEmitter<{}> = new EventEmitter<{}>();
  languages: any;
  speakerLang: any = {};
  currentVolumes: { original: number, translation: number } = { original: 1, translation: 0 };
  savedVolumes = {
    onAir: {
      original: 0.2,
      translation: 1
    },
    offAir: {
      original: 1,
      translation: 0
    }
  };
  firstChange: boolean = true;
  onAir: boolean = false;
  hover: boolean = false;

  constructor() {
  }

  sendPlayerAction(name: string, payload?: any) {
    this.playerAction.emit({ name, payload });
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.onAir) {
        this.sendPlayerAction('changeVolume', +this.savedVolumes.onAir.original);
        this.sendPlayerAction('changeTranslationVolume', +this.savedVolumes.onAir.translation);
        this.currentVolumes = this.savedVolumes.onAir;
      } else {
        this.sendPlayerAction('changeVolume', +this.savedVolumes.offAir.original);
        this.sendPlayerAction('changeTranslationVolume', +this.savedVolumes.offAir.translation);
        this.currentVolumes = this.savedVolumes.offAir;
      }
    }, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'langsFromParent':
            if (changes && changes.langsFromParent) {
              this.languages = changes.langsFromParent.currentValue;
              console.log('ngOnChanges: langsFromParent: languages:', this.languages);
            }
            break;
          //case 'changedVolumes':
          //  if (changes && changes.changedVolumes) {
          //    if (changes.changedVolumes.currentValue !== undefined) {
          //      if (this.onAir) {
          //        this.savedVolumes.onAir = changes.changedVolumes.currentValue;
          //        this.currentVolumes = this.savedVolumes.onAir;
          //      } else {
          //        this.savedVolumes.offAir = changes.changedVolumes.currentValue;
          //        this.currentVolumes = this.savedVolumes.offAir;
          //      }
          //    }
          //  }
          //  break;
          //case 'firstLanguageSwitch': {
          //  if (changes && changes.firstLanguageSwitch && changes.firstLanguageSwitch.currentValue === false) {
          //    this.firstChange = false;
          //    this.currentVolumes = this.defaultVolumes;
          //    this.onOriginalChange(this.currentVolumes.original);
          //    this.onTranslationChange(this.currentVolumes.translation);
          //    console.log('ngOnChanges: firstLanguageSwitch: FIRST SWITCH DONE');
          //  }
          //  break;
          case 'currLangOnAir':
            if (changes && changes.currLangOnAir && changes.currLangOnAir.currentValue !== changes.currLangOnAir.previousValue) {
              console.log('ngOnChanges: currLangOnAir: currentValue:', changes.currLangOnAir.currentValue + '\nonAir:', this.onAir)
              this.onAir = changes.currLangOnAir.currentValue;
              if (this.onAir) {
                this.sendPlayerAction('changeVolume', +this.savedVolumes.onAir.original);
                this.sendPlayerAction('changeTranslationVolume', +this.savedVolumes.onAir.translation);
                this.currentVolumes = this.savedVolumes.onAir;
              } else {
                this.sendPlayerAction('changeVolume', +this.savedVolumes.offAir.original);
                this.sendPlayerAction('changeTranslationVolume', +this.savedVolumes.offAir.translation);
                this.currentVolumes = this.savedVolumes.offAir;
              }
            }
            break;
        }
      }
    }
  }

  onOriginalChange(newVolume) {
    if (this.onAir) {
      this.savedVolumes.onAir.original = newVolume;
    } else {
      this.savedVolumes.offAir.original = newVolume;
    }

    this.sendPlayerAction('changeVolume', +newVolume);
  }

  onTranslationChange(newVolume) {
    if (this.onAir) {
      this.savedVolumes.onAir.translation = newVolume;
    } else {
      this.savedVolumes.offAir.translation = newVolume;
    }

    //if (!this.volumesTouched) this.volumesTouched = true;
    this.sendPlayerAction('changeTranslationVolume', +newVolume);
  }

  onLanguageSelected(language) {
    // PREV: this.langNameDisplay.nativeElement.innerHTML = '&nbsp;&nbsp;' + language.desc + '&nbsp;(' + language.languageCode.split('_')[0] + ')';
    this.langNameDisplay.nativeElement.innerHTML = language.desc;
    this.sendPlayerAction('selectLang', language);
  }

  onMouseHover() {
    this.hover = true;
  }

  onMouseOut() {
    this.hover = false;
  }
}
