import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlashMessage } from '../../../models/FlashMessage';
import { FlashMessagesService } from '../../../services/util/flash-messages.service';

@Component({
  selector: 'app-flash-messages',
  templateUrl: './flash-messages.component.html',
  styleUrls: ['./flash-messages.component.css']
})
export class FlashMessagesComponent implements OnInit, OnDestroy {
  messages: FlashMessage[];
  grayOut: boolean;
  private subscriptions = new Subscription();

  constructor(private flashMessagesServ: FlashMessagesService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.flashMessagesServ.messagesChange.subscribe(value => this.messages = value));
    this.subscriptions.add(this.flashMessagesServ.grayOutChange.subscribe(value => this.grayOut = value));
  }

  alertClicked(message: FlashMessage) {
    if (message.closeOnClick) {
      this.flashMessagesServ.closeMessage(message);
    }
  }

  close(message: FlashMessage) {
    this.flashMessagesServ.closeMessage(message);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
