import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {UserService} from '../../../services/user/user.service';
import {User} from '../../../models/User';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  user;
  error;
  submitted: boolean;

  constructor(
    private router: Router,
    private authServ: AuthService,
    private userServ: UserService
  ) {
  }

  ngOnInit() {
    this.user = {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      password: '',
      terms: false
    };
  }

  public showError(input): boolean {
    return (input.invalid && (input.touched || this.submitted));
  }

  public onSubmit(signupForm) {
    this.submitted = true;
    if (signupForm.invalid) {
      return;
    }

    this.user.websiteLang = (typeof(Storage) !== 'undefined') && localStorage && localStorage.getItem('userWebsiteLanguage') && (localStorage.getItem('userWebsiteLanguage') !== null) && (localStorage.getItem('userWebsiteLanguage').length === 2) ? localStorage.getItem('userWebsiteLanguage') : 'en';
    this.authServ.signup(this.user).subscribe((data) => {
      this.userServ.setUser(<User>data);
      //return this.router.navigate(['/']);
      //return this.router.navigate(['/dashboard']);
      return this.router.navigate(['/thanks-message']);
    }, (err) => {
      console.log(err);
      this.error = err.error;
      setTimeout(() => { this.error = null; }, 5000);
    });
  }
}
