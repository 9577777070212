import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { StripeService } from '../../../../services/stripe/stripe.service';
import { UserService } from '../../../../services/user/user.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';

declare var Stripe: any;

/*
  nextURL: route optional paramater (string) that can contain query params (/myurl?myparam=paramvalue), the router
  will navigate to nextURL if passed, or will navigate to last previous location if not passed.
*/
@Component({
  selector: 'app-change-payment',
  templateUrl: './change-payment.component.html',
  styleUrls: ['./change-payment.component.css']
})
export class ChangePaymentComponent implements AfterViewInit {
  private card: any;
  stripe = Stripe(environment.stripePublicKey);
  elements = this.stripe.elements();
  success = false;
  error = ' ';
  disable = false;
  @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('cardErrors') cardErrors: ElementRef;
  @ViewChild('paymentForm') paymentForm: ElementRef;

  constructor(
    private location: Location,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private stripeServ: StripeService,
    private userServ: UserService,
    private flashMessagesServ: FlashMessagesService
  ) {
  }

  ngAfterViewInit() {
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    // Create an instance of the card Element.
    this.card = this.elements.create('card', { style });
    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      if (error) {
        this.error = error.message;
      } else {
        this.error = ' ';
      }
    });
  }

  stripeTokenHandler(token) {
    this.stripeServ.saveCustomer(token, (user) => {
      this.flashMessagesServ.success('Payment saved successfully');
      const currentUser = this.userServ.getUser();
      currentUser.paymentMethod = user.paymentMethod;
      this.userServ.setUser(currentUser);

      const nextURL = this.route.snapshot.paramMap.get('nextURL');
      if (nextURL) {
        this.router.navigateByUrl(nextURL);
      } else {
        this.location.back();
      }
    },
    (error) => {
      this.flashMessagesServ.error('Error saving payment: ' + error.error.message);
      this.disable = false;
    });
  }

  async onSubmit() {
    if (this.error !== ' ') {
      return;
    }

    this.disable = true;

    // Create a token or display an error when the form is submitted.
    const { token, error } = await this.stripe.createToken(this.card);
    if (error) {
      // Inform the customer that there was an error.
      this.error = error.message;
      this.disable = false;
    } else {
      this.success = true;
      this.stripeTokenHandler(token);
    }
  }
}
