import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/index';
import { UserGroup } from '../models/User';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authServ: AuthService,
    private userServ: UserService
  ) {
  }

  checkSignin(url: string, groups: Array<UserGroup>, notFromAblio): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.authServ.isAuthenticated.subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          // Store the attempted URL for redirecting
          this.authServ.redirectUrl = url;

          // Navigate to the signin page with extras
          this.router.navigate(['/signin']);
          return observer.next(false);
        }

        let isAuthorized = true;
        if (groups && groups.length) {
          // check if the user has the required groups
          const found = groups.some(r => this.userServ.getUser().groups.indexOf(r) > -1);
          if (!found) {
            isAuthorized = false;
          }
        }

        // check if the user is coming from ablio
        if (notFromAblio && this.userServ.getUser().ablioId) {
          isAuthorized = false;
        }

        // Authorized user
        if (isAuthorized) {
          return observer.next(true);
        }

        // User not authorized
        this.authServ.redirectUserByGroup();
        return observer.next(false);
      });
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    /*
    groups must be passed as parameter when the path is accessible only by certain groups. Example for owners:
    canActivate: [AuthGuard],
    data: { groups: [UserGroup.OWNER] },
    */
    const groups = next.data['groups'] as Array<UserGroup>;
    /*
    notFromAblio must be passed as parameter when the path is not accessible for interpreters coming from ablio
    canActivate: [AuthGuard],
    data: { notFromAblio: true },
    */
    const notFromAblio = next.data['notFromAblio'] as boolean;
    return this.checkSignin(state.url, groups, notFromAblio);
  }
}
