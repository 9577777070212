import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/index';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class NotSignedInGuard implements CanActivate {

  constructor(private router: Router, private authServ: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.checkNotSignedIn();
  }

  checkNotSignedIn(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.authServ.isAuthenticated.subscribe( isAuthenticated => {
        if (!isAuthenticated) { return observer.next(true); }

        // Navigate to the home page
        this.router.navigate(['/']);
        return observer.next(false);
      });
    });
  }
}
