import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs/index';

import { AuthService } from '../../../services/auth/auth.service';
import { UserService } from '../../../services/user/user.service';
import { User } from '../../../models/User';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  user: User;
  private subscription: Subscription;
  today = new Date();
  websiteLang = (typeof(Storage) !== 'undefined') && localStorage && localStorage.getItem('userWebsiteLanguage') && (localStorage.getItem('userWebsiteLanguage') !== null) && (localStorage.getItem('userWebsiteLanguage').length === 2) ? localStorage.getItem('userWebsiteLanguage') : 'en';
  blogURL: string = 'https://blog.ablio.com/';
  twitterURL: string = 'https://twitter.com/abliodotcom';

  constructor(
    public sanitizer: DomSanitizer,
    private authServ: AuthService,
    private userServ: UserService
  ) {
  }

  ngOnInit() {
    this.subscription = this.userServ.userChange.subscribe(
      (user) => { this.user = user }
    );

    if (this.websiteLang) {
      if (this.websiteLang === 'es') {
        this.twitterURL = 'https://twitter.com/abliodotcom?lang=es';
      } else if (this.websiteLang === 'it') {
        this.blogURL = 'https://ablio.it/blog/';
        this.twitterURL = 'https://twitter.com/ablio_italia?lang=it';
      }
    }
  }
}
