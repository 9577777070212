import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  private readonly languages;
  private readonly twoCodesLanguages;
  private readonly languagesMap;
  private langsList: Array<string>;
  private twoCodesLangsList: Array<string>;

  constructor() {
    this.languages = 'af_ZA,am_ET,ar_TN,az_AZ,be_BY,bg_BG,bm_ML,bn_BD,bo_CN,bs_BA,ca_ES,cs_CZ,da_DK,de_DE,el_GR,en_US,es_ES,et_EE,fa_IR,fi_FI,fr_FR,gl_ES,gu_IN,ha_NE,he_IL,hi_IN,hr_HR,ht_HT,hu_HU,hy_AM,id_ID,ig_GQ,is_IS,it_IT,ja_JP,ka_GE,kk_KZ,kn_IN,ko_KR,ku_IQ,ky_KG,ln_CD,lt_LT,lv_LV,mk_MK,mn_MN,mr_IN,ms_MY,mt_MT,my_MM,ne_NP,nl_NL,no_NO,om_ET,pa_IN,pl_PL,pr_AF,ps_AF,pt_PT,rh_MM,ro_RO,ru_RU,sa_IN,si_LK,sk_SK,sl_SI,so_SO,sq_AL,sr_RS,sv_SE,sw_KE,ta_IN,te_IN,th_TH,ti_ER,tl_PH,tr_TR,tt_RU,uk_UA,ur_PK,uz_UZ,vi_VN,wo_SN,yo_NG,mc_CN,cc_CN,hm_CN';
    this.twoCodesLanguages = 'af,am,ar,az,be,bg,bm,bn,bo,bs,ca,cs,da,de,el,en,es,et,fa,fi,fr,gl,gu,ha,he,hi,hr,ht,hu,hy,id,ig,is,it,ja,ka,kk,kn,ko,ku,ky,ln,lt,lv,mk,mn,mr,ms,mt,my,ne,nl,no,om,pa,pl,pr,ps,pt,rh,ro,ru,sa,si,sk,sl,so,sq,sr,sv,sw,ta,te,th,ti,tl,tr,tt,uk,ur,uz,vi,wo,yo,mc,cc,hm';
    this.langsList = this.languages.split(',');
    this.twoCodesLangsList = this.twoCodesLanguages.split(',');
    this.languagesMap = {
      'af_ZA': {
        enname: 'Afrikaans',
        nativename: 'Afrikaans',
        country: 'South Africa'
      },
      'ai_IQ': {
        enname: 'Assyrian Neo-Aramaic',
        nativename: 'Ātūrāyā',
        country: 'Iraq'
      },
      'am_ET': {
        enname: 'Amharic',
        nativename: 'አማርኛ',
        country: 'Ethiopia'
      },
      'ar_TN': {
        enname: 'Arabic',
        nativename: 'العربية',
        country: 'Tunisia'
      },
      'as_IN': {
        enname: 'Assamese',
        nativename: 'অসমীয়া',
        country: 'India'
      },
      'az_AZ': {
        enname: 'Azerbaijani',
        nativename: 'Azərbaycan dili',
        country: 'Azerbaijan'
      },
      'be_BY': {
        enname: 'Belarusian',
        nativename: 'беларуская мова',
        country: 'Belarus'
      },
      'bg_BG': {
        enname: 'Bulgarian',
        nativename: 'български език',
        country: 'Bulgaria'
      },
      'bm_ML': {
        enname: 'Bambara',
        nativename: 'ߓߊߡߊߣߊߣߞߊߣ',
        country: 'Mali'
      },
      'bn_BD': {
        enname: 'Bengali',
        nativename: 'বাংলা',
        country: 'Bangladesh'
      },
      'bo_CN': {
        enname: 'Tibetan',
        nativename: 'བོད་ཡིག', // (ོད་ཡིག)
        country: 'China'
      },
      'bs_BA': {
        enname: 'Bosnian',
        nativename: 'Bosanski jezik',
        country: 'Bosnia and Herzegovina'
      },
      'ca_ES': {
        enname: 'Catalan',
        nativename: 'Català',
        country: 'Spain'
      },
      'cl_IQ': {
        enname: 'Chaldean Neo-Aramaic',
        nativename: 'Kaldāyâ',
        country: 'Iraq'
      },
      'cs_CZ': {
        enname: 'Czech',
        nativename: 'Čeština',
        country: 'Czech Republic'
      },
      'cy_UK': {
        enname: 'Welsh',
        nativename: 'Cymraeg', // it: 'Gallese'
        country: 'Unitedd Kingdom'
      },
      'da_DK': {
        enname: 'Danish',
        nativename: 'Dansk',
        country: 'Denmark'
      },
      'de_DE': {
        enname: 'German',
        nativename: 'Deutsch',
        country: 'Germany'
      },
      'el_GR': {
        enname: 'Greek',
        nativename: 'Ελληνικά',
        country: 'Greece'
      },
      'en_US': {
        enname: 'English',
        nativename: 'English',
        country: 'United States'
      },
      'es_ES': {
        enname: 'Spanish',
        nativename: 'Español',
        country: 'Spain'
      },
      'et_EE': {
        enname: 'Estonian',
        nativename: 'Eesti keel',
        country: 'Estonia'
      },
      'eu_ES': {
        enname: 'Basque',
        nativename: 'Euskara',
        country: 'Spain'
      },
      'fa_IR': {
        enname: 'Persian (Farsi)',
        nativename: 'فارسی',
        country: 'Iran', // Iran, Islamic Republic of
      },
      'fi_FI': {
        enname: 'Finnish',
        nativename: 'Suomi',
        country: 'Finland'
      },
      'fo_FO': {
        enname: 'Faroese',
        nativename: 'Føroyskt',
        country: 'Faroe Islands'
      },
      'fr_FR': {
        enname: 'French',
        nativename: 'Français',
        country: 'France'
      },
      'ga_IE': {
        enname: 'Irish',
        nativename: 'Gaeilge',
        country: 'Republic of Ireland'
      },
      'gd_UK': {
        enname: 'Scottish Gaelic',
        nativename: 'Gàidhlig',
        country: 'United Kingdom'
      },
      'gl_ES': {
        enname: 'Galician',
        nativename: 'Galego',
        country: 'Spain'
      },
      'gn_PY': {
        enname: 'Guaraní',
        nativename: 'Avañe’ẽ',
        country: 'Paraguay'
      },
      'gu_IN': {
        enname: 'Gujarati',
        nativename: 'ગુજરાતી',
        country: 'India'
      },
      'ha_NE': {
        enname: 'Hausa',
        nativename: 'Hausa',
        country: 'Niger'
      },
      'he_IL': {
        enname: 'Hebrew',
        nativename: 'עברית',
        country: 'Israel'
      },
      'hi_IN': {
        enname: 'Hindi',
        nativename: 'हिन्दी',
        country: 'India'
      },
      'hr_HR': {
        enname: 'Croatian',
        nativename: 'Hrvatski jezik',
        country: 'Croatia'
      },
      'ht_HT': {
        enname: 'Haitian Creole',
        nativename: 'Kreyòl',
        country: 'Haiti'
      },
      'hu_HU': {
        enname: 'Hungarian',
        nativename: 'Magyar',
        country: 'Hungary'
      },
      'hy_AM': {
        enname: 'Armenian',
        nativename: 'Հայերեն',
        country: 'Armenia'
      },
      'id_ID': {
        enname: 'Indonesian',
        nativename: 'Bahasa Indonesia',
        country: 'Indonesia'
      },
      'ig_GQ': {
        enname: 'Igbo',
        nativename: 'Asụsụ Igbo',
        country: 'Equatorial Guinea'
      },
      'is_IS': {
        enname: 'Icelandic',
        nativename: 'Íslenska',
        country: 'Iceland'
      },
      'it_IT': {
        enname: 'Italian',
        nativename: 'Italiano',
        country: 'Italy'
      },
      'ja_JP': {
        enname: 'Japanese',
        nativename: '日本語 (にほんご)',
        country: 'Japan'
      },
      'jv_ID': {
        enname: 'Javanese',
        nativename: 'basa Jawa',
        country: 'Indonesia'
      },
      'ka_GE': {
        enname: 'Georgian',
        nativename: 'ქართული',
        country: 'Georgia'
      },
      'kg_CD': {
        enname: 'Kongo',
        nativename: 'Kikongo',
        country: 'Democratic Republic of the Congo'
      },
      'kk_KZ': {
        enname: 'Kazakh',
        nativename: 'қазақ  тілі',
        country: 'Kazakhstan'
      },
      'km_KH': {
        enname: 'Khmer',
        nativename: 'ភាសាខ្មែរ',
        country: 'Cambodia'
      },
      'kn_IN': {
        enname: 'Kannada',
        nativename: 'ಕನ್ನಡ',
        country: 'India'
      },
      'ko_KR': {
        enname: 'Korean',
        nativename: '한국어, 조선어',
        country: 'South Korea'
      },
      'ku_IQ': {
        enname: 'Kurdish',
        nativename: 'Kurdî',
        country: 'Iraq'
      },
      'ky_KG': {
        enname: 'Kyrgyz',
        nativename: 'Кыргызча',
        country: 'Kyrgyzstan'
      },
      'ln_CD': {
        enname: 'Lingala',
        nativename: 'Lingála',
        country: 'Democratic Republic of the Congo'
      },
      'lo_LA': {
        enname: 'Lao',
        nativename: 'ພາສາລາວ',
        country: 'Laos'
      },
      'lt_LT': {
        enname: 'Lithuanian',
        nativename: 'Lietuvių kalba',
        country: 'Lithuania'
      },
      'lv_LV': {
        enname: 'Latvian',
        nativename: 'Latviešu valoda',
        country: 'Latvia'
      },
      'mk_MK': {
        enname: 'Macedonian',
        nativename: 'Македонски јазик',
        country: 'Macedonia, Republic of', // , the former Yugoslav Republic of
      },
      'ml_IN': {
        enname: 'Malayalam',
        nativename: 'മലയാളം',
        country: 'India'
      },
      'mn_MN': {
        enname: 'Mongolian',
        nativename: 'Монгол',
        country: 'Mongolia'
      },
      'mr_IN': {
        enname: 'Marathi',
        nativename: 'मराठी',
        country: 'India'
      },
      'ms_MY': {
        enname: 'Malay',
        nativename: 'Bahasa Melayu',
        country: 'Malaysia'
      },
      'mt_MT': {
        enname: 'Maltese',
        nativename: 'Malti',
        country: 'Malta'
      },
      'my_MM': {
        enname: 'Burmese',
        nativename: 'မြန်မာစကား', // 'ဗမာစာ'
        country: 'Myanmar'
      },
      'nb_NO': {
        enname: 'Norwegian Bokmål',
        nativename: 'Norsk bokmål',
        country: 'Norway'
      },
      'ne_NP': {
        enname: 'Nepali',
        nativename: 'नेपाली', // it: 'Nepalese'
        country: 'Nepal'
      },
      'nl_NL': {
        enname: 'Dutch',
        nativename: 'Nederlands', // Vlaams
        country: 'Netherlands'
      },
      'no_NO': {
        enname: 'Norwegian',
        nativename: 'Norsk',
        country: 'Norway'
      },
      'om_ET': {
        enname: 'Oromo',
        nativename: 'Afaan Oromoo',
        country: 'Ethiopia'
      },
      'pa_IN': {
        enname: 'Punjabi', // Panjabi
        nativename: 'ਪੰਜਾਬੀ', // پنجابی
        country: 'India'
      },
      'pl_PL': {
        enname: 'Polish',
        nativename: 'Język polski', // polszczyzna
        country: 'Poland'
      },
      'pr_AF': {
        enname: 'Dari', // Dari Persian, Afghan Persian
        nativename: 'دری',
        country: 'Afghanistan'
      },
      'ps_AF': {
        enname: 'Pashto',
        nativename: 'پښتو',
        country: 'Afghanistan'
      },
      'pt_PT': {
        enname: 'Portuguese',
        nativename: 'Português',
        country: 'Portugal'
      },
      'qu_PE': {
        enname: 'Quechua',
        nativename: 'Runa Simi',
        country: 'Peru'
      },
      'rh_MM': {
        enname: 'Rohingya',
        nativename: 'Ruáingga', // رُاَࣺينڠَ
        country: 'Myanmar'
      },
      'rn_BI': {
        enname: 'Kirundi',
        nativename: 'Ikirundi',
        country: 'Burundi'
      },
      'ro_RO': {
        enname: 'Romanian',
        nativename: 'Limba română',
        country: 'Romania'
      },
      'ru_RU': {
        enname: 'Russian',
        nativename: 'Русский',
        country: 'Russia', // Russian Federation
      },
      'rw_RW': {
        enname: 'Kinyarwanda',
        nativename: 'Ikinyarwanda',
        country: 'Rwanda'
      },
      'sa_IN': {
        enname: 'Sanskrit',
        nativename: 'संस्कृतम्',
        country: 'India'
      },
      'si_LK': {
        enname: 'Sinhalese',
        nativename: 'සිංහල',
        country: 'Sri Lanka'
      },
      'sk_SK': {
        enname: 'Slovak',
        nativename: 'Slovenčina', // slovenský jazyk
        country: 'Slovakia'
      },
      'sl_SI': {
        enname: 'Slovene',
        nativename: 'Slovenski jezik', // slovenščina
        country: 'Slovenia'
      },
      'so_SO': {
        enname: 'Somali',
        nativename: 'Af-Soomaali', // اف سومالى
        country: 'Somalia'
      },
      'sq_AL': {
        enname: 'Albanian',
        nativename: 'Shqip', // gjuha shqipe
        country: 'Albania'
      },
      'sr_RS': {
        enname: 'Serbian',
        nativename: 'Српски језик',
        country: 'Serbia'
      },
      'sv_SE': {
        enname: 'Swedish',
        nativename: 'Svenska',
        country: 'Sweden'
      },
      'sw_KE': {
        enname: 'Swahili',
        nativename: 'Kiswahili',
        country: 'Kenya'
      },
      'ta_IN': {
        enname: 'Tamil',
        nativename: 'தமிழ்',
        country: 'India'
      },
      'te_IN': {
        enname: 'Telugu',
        nativename: 'తెలుగు',
        country: 'India'
      },
      'th_TH': {
        enname: 'Thai',
        nativename: 'ไทย',
        country: 'Thailand'
      },
      'ti_ER': {
        enname: 'Tigrinya',
        nativename: 'ትግርኛ',
        country: 'Eritrea'
      },
      'tl_PH': {
        enname: 'Tagalog',
        nativename: 'Wikang Tagalog',
        country: 'Philippines'
      },
      'to_TO': {
        enname: 'Tsonga',
        nativename: 'faka Tonga',
        country: 'Tonga'
      },
      'tr_TR': {
        enname: 'Turkish',
        nativename: 'Türkçe',
        country: 'Turkey'
      },
      'tt_RU': {
        enname: 'Tatar',
        nativename: 'Татар теле', // tatar tele
        country: 'Russia', // Russian Federation
      },
      'uk_UA': {
        enname: 'Ukrainian',
        nativename: 'Українська мова',
        country: 'Ukraine'
      },
      'ur_PK': {
        enname: 'Urdu',
        nativename: 'اردو',
        country: 'Pakistan'
      },
      'uz_UZ': {
        enname: 'Uzbek',
        nativename: 'Oʻzbekcha',
        country: 'Uzbekistan'
      },
      'vi_VN': {
        enname: 'Vietnamese',
        nativename: 'Tiếng Việt',
        country: 'Vietnam'
      },
      'wo_SN': {
        enname: 'Wolof',
        nativename: 'Wolof'
      },
      'yo_NG': {
        enname: 'Yoruba',
        nativename: 'Yorùbá',
        country: 'Nigeria'
      },
      'za_CN': {
        enname: 'Zhuang',
        nativename: 'Saɯ cueŋƅ', // 'Saw cuengh'
        country: 'China'
      },
      'zu_ZA': {
        enname: 'Zulu',
        nativename: 'isiZulu',
        country: 'South Africa'
      },
      'mc_CN': {
        enname: 'Chinese (Mandarin)',
        nativename: '普通话 (Pǔtōnghuà)',
        country: 'China'
      },
      'cc_CN': {
        enname: 'Chinese (Cantonese)',
        nativename: '广东话 (Gwóngdūng Wah)',
        country: 'China'
      },
      'hm_CN': {
        enname: 'Hmong',
        nativename: '苗語',
        country: 'China'
      }
    };

    // Sorting langList by english name
    this.langsList.sort((a, b) => {
      const ax = this.languagesMap[a];
      const bx = this.languagesMap[b];
      if (ax.enname < bx.enname) return -1;
      if (ax.enname > bx.enname) return 1;
      return 0;
    });
  }

  getLangsList() {
    return this.langsList;
  }

  getTwoCodesLangsList() {
    return this.twoCodesLangsList;
  }

  getLang(langCode: string) {
    return this.languagesMap[langCode];
  }

  getEngName(langCode: string) {
    const lang = this.languagesMap[langCode];
    if (lang) {
      return lang.enname;
    }
    return null;
  }

  getNativeName(langCode: string) {
    const lang = this.languagesMap[langCode];
    if (lang) {
      return lang.nativename;
    }
    return null;
  }
}
