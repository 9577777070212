import { Component, OnInit } from '@angular/core';
import { Location, PopStateEvent } from '@angular/common';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { environment } from '../environments/environment';
import { SeoService } from './services/util/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // lastPoppedUrl used to store last url
  private lastPoppedUrl: string;
  // yScrollStack used to store previous scroll positions
  private yScrollStack: number[] = [];

  private scrollFix(anchorElement, y: number = 0): void {
    if (anchorElement) {
      anchorElement.scrollIntoView();
      y = window.pageYOffset || document.documentElement.scrollTop;
      y = y - 60; // fixing navbar height
    }
    window.scrollTo(0, y);
  }

  constructor(
    private location: Location,
    private router: Router,
    private intercom: Intercom,
    private seoServ: SeoService
  ) {}

  ngOnInit() {
    // auto scrolling on route change
    this.location.subscribe((event: PopStateEvent) => {
      // TBC: conditional for not redirecting to the home after login
      if (event.url && (event.url !== '/') && (event.url !== '/home')) this.lastPoppedUrl = event.url;
    });

    this.router.events.subscribe((event: Event) => {
      // navigation to new page starts
      if (event instanceof NavigationStart) {
        //console.log('???DEBUG??? NavigationStart', event);

        // TODO: Show loading indicator

        // if going to new page
        if (event.url !== this.lastPoppedUrl) {
          // saving previous page vertical position
          this.yScrollStack.push(window.scrollY);
        }
      }

      // navigation to new page ends
      if (event instanceof NavigationEnd) {
        //console.log('???DEBUG??? NavigationEnd', event);

        // TODO: Hide loading indicator

        // Sending 'event.url' to SeoService
        setTimeout(() => this.seoServ.updateSEOData(event.url));

        const anchor = window.location.hash.substr(1);
        let element = null;
        if (anchor && (anchor.length > 0)) element = document.getElementById(anchor);
        // if going to previous url
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          // scroll to previous position
          this.scrollFix(element, this.yScrollStack.pop());
        } else {
          // scroll to top
          this.scrollFix(element);
        }
      }

      // navigation error
      if (event instanceof NavigationError) {
        console.log('NavigationError:', event.error);

        // TODO: Hide loading indicator
      }
    });

    // Intercom chat widget (for not logged in users)
    this.intercom.boot({
      app_id: environment.intercomAppId,
      widget: { 'activator': '#intercom' }
    });
  }
}
