// /app/atatus.handler.ts
import { ErrorHandler } from '@angular/core';
import * as atatus from 'atatus-js';
import { environment } from '../environments/environment';

atatus.config('d062465801ad4917a6bdf63c2d56e559', {
  allowedDomains: ['ablioconference.com', 'local.ablioconference.com']
}).install();

export class AtatusErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const errorMessage = error.originalError || error;
    // logging errors, otherwise they are not showed in browser console
    console.warn('atatus.handler: ERROR:', errorMessage);

    // Automatically reload page in case of loading chunk error
    const chunkFailedMsg = /Loading chunk [\d]+ failed/;
    if (errorMessage && errorMessage.message && chunkFailedMsg.test(errorMessage.message)) {
      return location.reload(true);
    }

    if (environment.enableProdMode && atatus) {
      // Send the error to Atatus
      atatus.notify(errorMessage);
    }
  }
}
