// /app/app.module.ts

// Angular
import { ErrorHandler, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
registerLocaleData(localeEs, 'es', localeEsExtra);
registerLocaleData(localeIt, 'it', localeItExtra);
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';

// External
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IntercomModule } from 'ng-intercom';
import { Ng2CompleterModule } from 'ng2-completer';
import { FileUploadModule } from 'ng2-file-upload';

/* Platform and Environment providers/directives/pipes */
// Configuration
import { environment } from '../environments/environment';

// Main component
import { AppComponent } from './app.component';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Atatus Error Handler
import { AtatusErrorHandler } from './atatus.handler';

// Guards
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateInterpreterGuard } from './guards/can-deactivate-interpreter.guard';
import { EventGuard } from './guards/event.guard';
import { NotSignedInGuard } from './guards/not-signed-in.guard';

// Services
import { AuthService } from './services/auth/auth.service';
import { EventService } from './services/event/event.service';
import { RequestInterceptorService } from './services/extend/request-interceptor.service';
import { KurentoService } from './services/kurento/kurento.service';
import { UserService } from './services/user/user.service';
import { CountryService } from './services/util/country.service';

// Modules
import { SharedModule } from './modules/shared/shared.module';
import { UtilModule } from './modules/util/util.module';

/* Platform Components */
/** Public website **/
// Shared components
import { FlashMessagesComponent } from './components/shared/flash-messages/flash-messages.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { HomeComponent } from './components/shared/home/home.component';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';
import { SitemapComponent } from './components/shared/sitemap/sitemap.component';

/*** User component ***/
import { PasswordForgotComponent } from './components/user/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './components/user/password-reset/password-reset.component';
import { ResellersProgramComponent } from './components/user/resellers-program/resellers-program.component';
import { SigninComponent } from './components/user/signin/signin.component';
import { SignupComponent } from './components/user/signup/signup.component';

// Private website
import { ThanksMessageComponent } from './components/user/thanks-message/thanks-message.component';

@NgModule({
  declarations: [
    // Main component
    AppComponent,
    FlashMessagesComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    PageNotFoundComponent,
    SitemapComponent,
    PasswordForgotComponent,
    PasswordResetComponent,
    ResellersProgramComponent,
    SigninComponent,
    SignupComponent,
    ThanksMessageComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxDatatableModule,
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true // will automatically run `update` on router event changes
    }),
    Ng2CompleterModule,
    FileUploadModule,
    AppRoutingModule,
    SharedModule,
    UtilModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: AtatusErrorHandler
    },
    Meta,
    Title,
    AuthGuard,
    CanDeactivateInterpreterGuard,
    EventGuard,
    NotSignedInGuard,
    AuthService,
    EventService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    KurentoService,
    UserService,
    CountryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
