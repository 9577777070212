import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { User } from '../../../models/User';
import { AuthService } from '../../../services/auth/auth.service';
import { IntercomService } from '../../../services/intercom/intercom.service';
import { UserService } from '../../../services/user/user.service';
import { FlashMessagesService } from '../../../services/util/flash-messages.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  tokenChecked: boolean;
  tokenIsExpired: boolean;
  tokenIsValid: boolean;
  username: string;
  newPassword: string;
  verifyPassword: string;
  error: boolean;
  success: boolean;
  user: any;
  // NOT USED: userType: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private intercom: Intercom,
    private authServ: AuthService,
    private intercomServ: IntercomService,
    private userServ: UserService,
    private flashMessagesServ: FlashMessagesService
  ) {}

  ngOnInit() {
    this.tokenChecked = false;
    this.tokenIsExpired = false;
    this.tokenIsValid = false;
    this.username = '';
    this.newPassword = '';
    this.verifyPassword = '';
    this.user = {
      username: '',
      password: ''
    };
    const token = this.route.snapshot.queryParams.token || null;
    //console.log('token:', token);
    if (token) {
      // verify token exists and is not expired...
      this.authServ.validatetoken(token, (err, data) => {
        console.log(data || 'null');
        this.tokenChecked = true;

        if (err && (err.status !== 200)) {
          console.log(err);
          this.error = true;
          const msg = err.error.message || 'Invalid token';
          if (msg === 'Invalid token') return this.flashMessagesServ.error('Error: invalid token.');
          if (msg === 'Expired token') {
            this.tokenIsExpired = true;
            return this.flashMessagesServ.error('Error: expired token.');
          }

          return this.flashMessagesServ.error('Error: ' + err.error.message);
        }

        this.user = data;
        this.tokenIsValid = true;
      });
    } else {
        this.error = true;
        this.tokenChecked = true;
        //this.tokenIsValid = false;
        this.flashMessagesServ.error('Error: invalid token.');
    }
  }

  onSignin() {
    this.authServ.signin(this.user.username, this.newPassword).subscribe((data) => {
      const user = data['user'];
      this.userServ.checkLanguage(user);
      this.userServ.setUser(user);
      // ng-intercom update to send information data about the user like location by ip address
      this.intercom.update({
        user_id: user._id,
        email: user.email
      });
      this.intercomServ.trackEvent(user, 'signIn');
      if (this.authServ.redirectUrl) this.router.navigateByUrl(this.authServ.redirectUrl);
      else this.router.navigate(['/dashboard']);
    }, (err) => {
      console.log(err);
      this.error = true;
      this.flashMessagesServ.error('Error saving password: ' + err.error.message);
      //setTimeout(() => { this.error = false; }, 5000);
    });
  }

  onUsernameChange() {
    if (this.error) this.error = false;
  }

  showError(input): boolean {
    return (input.invalid && input.touched);
  }

  public onSubmit(resetPasswordForm) {
    if (resetPasswordForm.invalid || (this.username !== this.user.username) || (this.newPassword !== this.verifyPassword)) return;

    const tmpUser: any = this.user;
    tmpUser.newPassword = this.newPassword;
    this.authServ.passwordreset(tmpUser, (err, data) => {
      if (err) {
        console.log(err);
        return this.flashMessagesServ.error('Error saving password: ' + err.error.message);
      }

      this.user = data;
      this.flashMessagesServ.success('Success saving password');
      //setTimeout(() => { this.success = true; }, 5000);
      this.success = true;
      //this.intercomServ.trackEvent({ user_id: this.user.ablioId || this.user._id, email: this.user.email }, 'passwordReset');
      setTimeout(() => { this.onSignin(); }, 5000);
    });
  }
}
