import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilModule } from '../util/util.module';
import { AddToBalanceComponent } from './components/add-to-balance/add-to-balance.component';
import { AudienceComponent } from './components/audience/audience.component';
import { HttpAudienceComponent } from './components/http-audience/http-audience.component';
import { ChangePaymentComponent } from './components/change-payment/change-payment.component';
import { ChatComponent } from './components/chat/chat.component';
import { InterpreterComponent } from './components/interpreter/interpreter.component';
import { KurentoStatsComponent } from './components/kurento-stats/kurento-stats.component';
import { ProfileComponent } from './components/profile/profile.component';
import { KBroadcastComponent } from './components/speaker/speaker.component';
import { MeetingPlayerComponent } from './components/http-audience/meeting-player/meeting-player.component';
import { MeetingDebugComponent } from './components/http-audience/meeting-debug/meeting-debug.component';
import { MeetingControlbarComponent } from './components/http-audience/meeting-controlbar/meeting-controlbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UtilModule
  ],
  declarations: [
    AddToBalanceComponent,
    AudienceComponent,
    HttpAudienceComponent,
    ChangePaymentComponent,
    ChatComponent,
    InterpreterComponent,
    KurentoStatsComponent,
    ProfileComponent,
    KBroadcastComponent,
    MeetingPlayerComponent,
    MeetingDebugComponent,
    MeetingControlbarComponent
  ],
  exports: [
    AddToBalanceComponent,
    AudienceComponent,
    HttpAudienceComponent,
    MeetingPlayerComponent,
    ChatComponent,
    InterpreterComponent,
    KurentoStatsComponent,
    ProfileComponent,
    KBroadcastComponent
  ]
})
export class SharedModule { }
