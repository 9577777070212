import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { User } from '../../../models/User';
import { AuthService } from '../../../services/auth/auth.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: User;
  availableLangs = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'it', label: 'Italiano' }
  ];
  websiteLang = (typeof(Storage) !== 'undefined') && localStorage && localStorage.getItem('userWebsiteLanguage') && (localStorage.getItem('userWebsiteLanguage') !== null) && (localStorage.getItem('userWebsiteLanguage').length === 2) ? localStorage.getItem('userWebsiteLanguage') : 'en';
  private subscription: Subscription;

  constructor(private authServ: AuthService, private userServ: UserService) {
  }

  ngOnInit() {
    this.subscription = this.userServ.userChange.subscribe((user) => {
        this.user = user;
    });
  }

  public onSignout() {
    this.authServ.signout();
  }

  public setLanguage = (language) => {
    try {
      localStorage.setItem('userWebsiteLanguage', language);
      console.log('locale set to: ' + language);
    } catch(e) {
      console.log(e.message);
    }

    if (this.user) {
      // status: ['newlycreated', 'invited', 'active', 'sleeping', 'unpaid-billing', 'inreview', 'suspended', 'blocked', 'abusive']
      if (['newlycreated', 'invited', 'sleeping'].indexOf(this.user.status) > -1)  {
        this.user.status = 'active';
      }
      this.user.websiteLanguage = language;
      this.userServ.update(this.user, (err, data) => {
        if (err) {
          console.log('Error saving user websiteLanguage:', err);
        } else {
          console.log('Success saving user websiteLanguage');
        }
      });
    }

    location.reload(true);
  }
}
