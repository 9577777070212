import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserGroup } from '../../../models/User';
import { AuthService } from '../../../services/auth/auth.service';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-resellers-program',
  templateUrl: './resellers-program.component.html',
  styleUrls: ['./resellers-program.component.css']
})
export class ResellersProgramComponent implements OnInit {
  user;
  error;
  submitted: boolean;
  requiredService: boolean = false;
  requiredSize: boolean = false;

  constructor(private router: Router, private authServ: AuthService, private userServ: UserService) {}

  ngOnInit() {
    this.user = {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      password: '',
      mobile: '',
      phone: '',
      reseller: {
        company: '',
        phone: '',
        translationsByYear: 0,
        website: '',
        services: {},
        sizes: {},
        areas: { worldwide: true },
        notes: ''
      },
      terms: false
    };
  }

  showError(field): boolean {
    return field.invalid && (field.touched || this.submitted);
  }

  onServicesChanged() {
    this.requiredService = false;
    if (!this.user || !this.user.reseller || !this.user.reseller.services || (
        !this.user.reseller.services.techonsite &&
        !this.user.reseller.services.techweb &&
        !this.user.reseller.services.audiovideo &&
        !this.user.reseller.services.lighting &&
        !this.user.reseller.services.translation &&
        !this.user.reseller.services.remotebooths &&
        !this.user.reseller.services.staging &&
        !this.user.reseller.services.management &&
        !this.user.reseller.services.hostessing
      )) {
        this.requiredService = true;
      }
  }

  onSizesChanged() {
    this.requiredSize = false;
    if (!this.user || !this.user.reseller || !this.user.reseller.sizes || (
        !this.user.reseller.sizes.small &&
        !this.user.reseller.sizes.medium &&
        !this.user.reseller.sizes.large &&
        !this.user.reseller.sizes.xlarge
      )) {
        this.requiredSize = true;
      }
  }

  onAreaChanged(area) {
    // TODO: save the selected area in the user model
    console.log('Showing selected areas from resellers component');
    console.log(area);
    this.user.reseller.areas = area;
  }

  public onSubmit(resellerSignUpForm) {
    this.submitted = true;
    if (!this.user || !this.user.reseller || resellerSignUpForm.invalid) {
      if (!this.user.reseller.services || (
        !this.user.reseller.services.techonsite &&
        !this.user.reseller.services.techweb &&
        !this.user.reseller.services.audiovideo &&
        !this.user.reseller.services.lighting &&
        !this.user.reseller.services.translation &&
        !this.user.reseller.services.remotebooths &&
        !this.user.reseller.services.staging &&
        !this.user.reseller.services.management &&
        !this.user.reseller.services.hostessing
      )) {
        this.requiredService = true;
      }
      if (!this.user.reseller.sizes || (
        !this.user.reseller.sizes.small &&
        !this.user.reseller.sizes.medium &&
        !this.user.reseller.sizes.large &&
        !this.user.reseller.sizes.xlarge
      )) {
        this.requiredSize = true;
      }

      return;
    }

    this.user.groups = [UserGroup.RESELLER_CANDIDATE, UserGroup.OWNER, UserGroup.EVENT_MASTER, UserGroup.EVENT_MANAGER];
    this.authServ.signup(this.user).subscribe((data) => {
      this.userServ.setUser(<User>data);
      //return this.router.navigate(['/dashboard']);
      return this.router.navigate(['/thanks-message']);
    }, (err) => {
      console.log(err);
      this.error = err.error;
      setTimeout(() => { this.error = null; }, 5000);
    });
  }
}
