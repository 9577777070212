import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LanguagesService} from '../../../../services/util/languages.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.css']
})
export class LangSelectorComponent implements OnInit {
  static waitingFilePath = environment.serverUrl + '/stream/files/';
  @Input() disabled: boolean;
  @Input() langCode: string;
  @Output() langCodeChange: EventEmitter<string> = new EventEmitter();
  @Input() desc: string;
  @Output() descChange: EventEmitter<string> = new EventEmitter();
  @Input() waitingFile: string;
  @Output() waitingFileChange: EventEmitter<string> = new EventEmitter();

  constructor(public langsServ: LanguagesService) {
  }

  ngOnInit() {
  }

  selectionChanged() {
    this.langCodeChange.emit(this.langCode);
    const newDesc = this.langsServ.getEngName(this.langCode);
    this.descChange.emit(newDesc);
    // TODO: adding this directly in the UI because later we will let the manager to set his own waiting file for each lang
    const newWaitingFile = LangSelectorComponent.waitingFilePath + this.langCode + '.mp3';
    this.waitingFileChange.emit(newWaitingFile);
  }

  showError(input): boolean {
    return (input.invalid && input.touched);
  }
}
