import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Intercom } from 'ng-intercom';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';

/**
 * Service used to update intercom users information in the front end.
 * By now updateLastSeen and updateUser methods are not used because we are updating the information directly within
 * the node backend. Better do it directly in the backend, if not we use this service.
 */

@Injectable({
  providedIn: 'root'
})

export class IntercomService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private http: HttpClient,
    private intercom: Intercom,
    private userServ: UserService
  ) {
    this.subscriptions.add(this.userServ.userChange.subscribe((user) => {
      // This part of the code is used to associate the chat information with the current user
      const intercomData = {
        app_id: environment.intercomAppId,
        // Supports all optional configuration.
        widget: {
          'activator': '#intercom'
        }
      };
      if (user && (user._id || user.ablioId) && user.email) {
        intercomData['user_id'] = user.ablioId || user._id;
        intercomData['email'] = user.email;
      }
      this.intercom.boot(intercomData);
    }));
  }

  // Method used to update the last seen intercom attribute for a user
  public updateLastSeen(user) {
    if (!user || !user._id) return;

    this.http.put(`${environment.apiUrl}/intercom/user/last-seen`, {})
    .subscribe((data) => {
      // console.log(data);
    },
    (err) => {
      console.log('Error updating intercom last seen attribute');
      console.log(err);
    });
  }

  // Method used to update an intercom user
  public updateUser(user) {
    if (!user || !user._id) return;

    this.http.put(`${environment.apiUrl}/intercom/user`, user)
    .subscribe((data) => {
      // console.log(data);
    },
    (err) => {
      console.log('Error updating intercom user');
      console.log(err);
    });
  }

  // Method used to update intercom users
  public updateUsers(users: any, callback: Function) {
    // console.log('???DEBUG???:users:', users);
    if (!users) return;

    return this.http.post(`${environment.apiUrl}/intercom/users`, users).subscribe((data: any) => {
      // console.log(data);
      callback(null, data);
    },
    (err) => {
      console.log('Error updating intercom users');
      console.log(err);
      callback(err);
    });
  }

  public trackEvent(user, eventName, metaData?) {
    if (!user || !user._id || !eventName) return;

    this.http.post(`${environment.apiUrl}/intercom/event`, {
      user_id: user.ablioId || user._id,
      eventName: eventName,
      metaData: metaData
    })
    .subscribe((data) => {
      // console.log(data);
    },
    (err) => {
      console.log('Error tracking intercom event');
      console.log(err);
    });
  }

  public shutdown(reboot) {
    this.intercom.shutdown();
    if (reboot) this.intercom.boot({ app_id: environment.intercomAppId, widget: { 'activator': '#intercom' }});
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
