import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OsService {
  OS = navigator.platform || navigator.userAgent;
  constructor() { }

  isMac() {
    return this.OS.indexOf('Mac') !== -1;
  }
}
