import { User } from './User';
import { PlaybackSession } from './PlaybackSession';

export enum SectionStatus {
  TODO = 'TODO',
  CONFIGURED = 'CONFIGURED',
  FROZEN = 'FROZEN'
}

export interface ContentDeliverySettings {
  // Title or name of the event
  title?: string;
  welcomingText?: string;
  // Path to image
  image?: string;
  // Availability set by checkbox on the dashboard
  available?: boolean;
}

// Uploaded file schema
export interface File {
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  destination: string;
  filename: string;
  path: string;
  size: number;
}

// NOT USED
export enum NetworkConfig {
  AUTO = 'auto',
  MANUAL = 'manual'
}

// NOT USED
export interface Network {
  name?: string;
  wapDevice?: {
    model?: string,
    positioning?: string
  };
  configuration?: NetworkConfig;
  score?: number;
}

export enum InterpreterStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected'
}

export enum Site {
  ONSITE = 'ONSITE',
  REMOTE = 'REMOTE'
}

export interface Interpreter {
  user?: string;
  site?: Site;
  ablio?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    username?: string;
    ablioId?: string;
  };
  status?: InterpreterStatus;
}

export interface Language {
  languageCode?: string;
  desc?: string;
  // Path of waiting/test file for each language
  waitingFile?: string;
  // Channel for audience
  channel?: number;
  // Max number of simultaneous connections
  maxAudienceExpected?: number;
  // active is true when the speaker is talking in this language
  active?: boolean;
  // Attributes used only within the client ui:
  volume?: number;
  playing?: boolean;
  disablePlayButton?: boolean;
  directStreamUrl?: string;
  // Https ports for streams
  ports?: number[];
}

export interface Booth {
  _id?: string;
  // Language 1
  languageCode1?: string;
  langDesc1?: string;
  // Language 2
  languageCode2?: string;
  langDesc2?: string;
  // List of interpreters which will translate from source to target language
  interpreters?: Interpreter[];
  requestToAblio?: string;
  previousRequestToAblio?: string;
}

// TODO: event.edition: ['standard', 'web-conference', 'educational', 'venue', 'playback']
export enum Edition {
  STANDARD = 'standard',
  WEB_CONFERENCE = 'web-conference',
  EDUCATIONAL = 'educational',
  VENUE = 'venue',
  PLAYBACK = 'playback'
}

// TODO: event.mode: ['cloud', 'local', 'mixed', 'playback']
export enum Mode {
  CLOUD = 'cloud',
  LOCAL = 'local',
  MIXED = 'mixed',
  PLAYBACK = 'playback'
}

// event.status: ['created', 'postponed', 'starting', 'ablio-test', 'test', 'in-progress', 'playback', 'cancelled', 'expired', 'completed', 'billed', 'processing', 'paid']
export enum EventStatus {
  CREATED = 'created',
  POSTPONED = 'postponed',
  STARTING = 'starting',
  ABLIO_TEST = 'ablio-test',
  TEST = 'test',
  IN_PROGRESS = 'in-progress',
  PLAYBACK = 'playback',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  COMPLETED = 'completed',
  BILLED = 'billed',
  PROCESSING = 'processing',
  PAID = 'paid'
}

export interface Event {
  _id?: string;
  code?: string;
  name?: string;
  date?: string;
  endDate?: string;
  country?: string;
  city?: string;
  // Edition of the event ['standard', 'web-conference', 'educational', 'venue', 'playback']
  edition?: string;
  // Broadcasting mode of the event ['cloud', 'local', 'mixed', 'playback']
  mode?: string;
  // status: ['created', 'postponed', 'starting', 'ablio-test', 'test', 'in-progress', 'playback', 'cancelled', 'expired', 'completed', 'billed', 'processing', 'paid']
  status?: EventStatus;
  subscribed?: boolean;
  owner?: string;
  eventMaster?: string;
  venueManager?: string;
  systemManager?: string;
  interpretersManager?: string;
  audienceAppManager?: string;
  venue?: {
    sectionStatus?: SectionStatus;
    // Name of the venue
    name?: string;
    // Type of the venue: indoor, outdoor, area, shape, etc
    kind?: string;
    // Capacity of the venue (number of persons allowed)
    capacity?: number;
    // Dimensions (Square meters)
    dimensions?: number;
    // Estimated number of persons that will attend to the event
    estimatedAudience?: number;
    // Venue address (Street)
    address?: string;
    // Venue manager
    manager?: string;
    // Venue email
    email?: string;
    // Venue phone
    phone?: string;
    // Venue has or not internet connection
    hasInternet?: boolean;
    // Venue internet capabilities
    internet?: {
      // Download speed MB/S
      downloadSpeed?: number;
      // Upload speed MB/S
      uploadSpeed?: number;
    };
  };
  /*
  // TODO: Add external source(s) like YouTube and Vimeo, or a stream URL
  externalSource: {
    // Name of the provider
    provider?: string;
    // ID of the video orthe  URL of the stream
    videoId?: string;
  */
  mediaServer?: {
    cloud: boolean; // true for 'cloud' and 'mixed', false for 'local' and 'playback'
    sectionStatus?: SectionStatus;
  };
  wifiNetworks?: {
    localBroadcaster?: boolean; // true for 'local', 'mixed', and 'playback', false for 'cloud'
    sectionStatus?: SectionStatus;
  };
  booths?: Booth[];
  languages?: Language[];
  speakerLanguage?: Language;
  briefingMaterials?: [File];
  program?: {
    interpreterBoothsStatus?: SectionStatus;
    interpretersStatus?: SectionStatus;
    sectionStatus?: SectionStatus;
    volume?: number;
  };
  playback?: {
    languagesStatus?: SectionStatus,
    sessionsStatus?: SectionStatus,
    sessions?: PlaybackSession[]
  };
  audience?: {
    // AblioAudience mobile app (always enabled)
    mobileApp?: ContentDeliverySettings;
    // DASH streaming ('cloud' mode only)
    webPortal?: ContentDeliverySettings;
    // Other websites/apps to be made available for audience
    websitesApps?: string[];
    // Payment options, 0 if no payment
    payment?: number;
    sectionStatus?: SectionStatus;
  };
  mediaHost?: string;
  secureMediaHost?: string;
  mediaPort?: number;
  kurentoHost?: string;
  instanceId?: string;
  // Media Servers running times ('in-progress' and 'cloud' or 'mixed' only)
  uptime?: [{
    start?: string;
    stop?: string;
  }];
  // Audience logs ('in-progress' and 'cloud' or 'mixed' only)
  loggedUsers?: string[];
  loggedUsersDASH?: string[];
  loggedUsersWebRTC?: string[];
  // KMS, WebRTC, and DASH statistics ('in-progress' and 'cloud' or 'mixed' only; NOT IMPLEMENTED YET)
  stats?: string
  created?: string;
}
