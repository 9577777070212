import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../models/User';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-thanks-message',
  templateUrl: './thanks-message.component.html',
  styleUrls: ['./thanks-message.component.css']
})
export class ThanksMessageComponent implements OnInit {
  user: User;
  private redirectTimer;

  constructor(private router: Router, private userServ: UserService) {
  }

  ngOnInit() {
    this.user = this.userServ.getUser();

    this.redirectTimer = setTimeout(() => {
      this.router.navigate(['/dashboard']);
    }, 30000);
  }

  cancelTimer() {
    // cancel redirect timer when the user clicks the link to go to dashboard, otherwise the redirection will happen
    // when the user is already navigating the site
    clearTimeout(this.redirectTimer);
  }
}
