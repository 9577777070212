import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoElementService {

  constructor() { }

  showSpinner(video, poster = './assets/img/transparent-1px.png') {
    video.autoplay = true;
    video.poster = poster;
    video.style.background = 'center transparent url("./assets/img/spinner.gif") no-repeat';
  }

  hideSpinner(video, poster = './images/commons/poster-1280x720.png') {
    video.src = '';
    video.poster = poster;
    video.style.background = '';
    video.autoplay = false;
  }
}
