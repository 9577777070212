import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGroup } from './models/User';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateInterpreterGuard } from './guards/can-deactivate-interpreter.guard';
import { NotSignedInGuard } from './guards/not-signed-in.guard';

/*** components ***/
// Shared
import { HomeComponent } from './components/shared/home/home.component';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';
import { SitemapComponent } from './components/shared/sitemap/sitemap.component';

// User
import { PasswordForgotComponent } from './components/user/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './components/user/password-reset/password-reset.component';
import { ResellersProgramComponent } from './components/user/resellers-program/resellers-program.component';
import { SigninComponent } from './components/user/signin/signin.component';
import { SignupComponent } from './components/user/signup/signup.component';
import { ThanksMessageComponent } from './components/user/thanks-message/thanks-message.component';

/*** modules ***/
import { AudienceComponent } from './modules/shared/components/audience/audience.component';
import { HttpAudienceComponent } from './modules/shared/components/http-audience/http-audience.component';
import { InterpreterComponent } from './modules/shared/components/interpreter/interpreter.component';
import { KurentoStatsComponent } from './modules/shared/components/kurento-stats/kurento-stats.component';
import { ProfileComponent } from './modules/shared/components/profile/profile.component';
import { KBroadcastComponent } from './modules/shared/components/speaker/speaker.component';

const appRoutes: Routes = [
  // Shared
  // Home
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'sitemap', component: SitemapComponent },
  // User
  { path: 'password-forgot', component: PasswordForgotComponent, canActivate: [NotSignedInGuard] },
  { path: 'password-reset', component: PasswordResetComponent, canActivate: [NotSignedInGuard] },
  { path: 'resellers-program', component: ResellersProgramComponent, canActivate: [NotSignedInGuard] },
  { path: 'signin', component: SigninComponent, canActivate: [NotSignedInGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [NotSignedInGuard] },
  { path: 'thanks-message', component: ThanksMessageComponent, canActivate: [AuthGuard] },
  // Modules
  { path: 'audience', component: AudienceComponent },
  { path: 'meeting', component: HttpAudienceComponent },
  { path: 'interpreter/:eventId/:langs', component: InterpreterComponent, canDeactivate: [CanDeactivateInterpreterGuard] },
  { path: 'statistics', component: KurentoStatsComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: { notFromAblio: true } },
  { path: 'speaker', component: KBroadcastComponent, canActivate: [AuthGuard], data: { groups: [UserGroup.ADMIN, UserGroup.RESELLER, UserGroup.RESELLER_CANDIDATE, UserGroup.OWNER, UserGroup.EVENT_MASTER, UserGroup.EVENT_MANAGER] } },
  // loadChildren
  { path: 'ablio-request', loadChildren: './modules/ablio-request/ablio-request.module#AblioRequestModule' },
  { path: 'admin', loadChildren: './modules/admin/admin.module#AdminModule' },
  { path: 'docs', loadChildren: './modules/docs/docs.module#DocsModule' },
  { path: 'dashboard', loadChildren: './modules/owner-dashboard/owner-dashboard.module#OwnerDashboardModule' },
  { path: 'dashboard', loadChildren: './modules/event-dashboard/event-dashboard.module#EventDashboardModule' },
  { path: 'user', loadChildren: './modules/user/user.module#UserModule' },
  // IMPORTANT: the route for the public module must remain at the bottom because otherwise it could match when
  // accessing other routes and make the public module load unnecessarily
  // TODO: load and show the public module first and then all the rest (no lazy).
  { path: '', loadChildren: './modules/public/public.module#PublicModule' },
  // PageNotFound otherwise
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes//,
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
