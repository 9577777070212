import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  showFeat = 3;
  websiteLang = (typeof(Storage) !== 'undefined') && localStorage && localStorage.getItem('userWebsiteLanguage') && (localStorage.getItem('userWebsiteLanguage') !== null) && (localStorage.getItem('userWebsiteLanguage').length === 2) ? localStorage.getItem('userWebsiteLanguage') : 'en';

  constructor() {
  }

  ngOnInit() {
  }
}
