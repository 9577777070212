import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http: HttpClient, private userServ: UserService) {
  }

  public saveCustomer(token, successcb, errorcb) {
    this.http.post(`${environment.apiUrl}/stripe/customer`, {
      stripeToken: token.id,
      email: this.userServ.getUser().email,
      description: this.userServ.getUser().username
    })
    .subscribe((data) => {
      successcb(data);
    },
    (err) => {
      console.log('Error saving user payment');
      console.log(err);
      errorcb(err);
    });
  }
}
