import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountryService } from '../../../../services/util/country.service';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.css']
})
export class CountrySelectorComponent implements OnInit {
  @Input() ngModel: any;
  @Input() countryCode: string;
  @Output() countryCodeChange: EventEmitter<string> = new EventEmitter();
  @Input() disabled: boolean;

  constructor(public countryServ: CountryService) {
  }

  ngOnInit() {
    //console.log('country-selector:ngOnInit: this.ngModel', this.ngModel);
    this.countryCode = this.ngModel || '';
  }

  selectionChanged() {
    //console.log('country-selector:selectionChanged: this.countryCode', this.countryCode);
    this.countryCode = this.ngModel;
    this.countryCodeChange.emit(this.ngModel);
  }

  /*
  showError(input): boolean {
    return (input.invalid && input.touched);
  }
  */
}
