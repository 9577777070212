// groups: ['resellr-candidate', 'reseller', 'owner', 'event-master', 'event-manager', 'interpreter', 'admin', 'operator']
export enum UserGroup {
  RESELLER = 'reseller',
  RESELLER_CANDIDATE = 'resellr-candidate',
  OWNER = 'owner',
  EVENT_MASTER = 'event-master',
  EVENT_MANAGER = 'event-manager',
  INTERPRETER = 'interpreter',
  ADMIN = 'admin',
  OPERATOR = 'operator'
}

export enum PaymentMethod {
  CARD = 'card',
  PAYPAL = 'paypal',
  BANK = 'bank'
}

export class User {
  _id?: string;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  singularity?: string;
  terms?: boolean;
  // TOSO: address?: string;
  zip?: string;
  city?: string;
  state?: string;
  country?: string;
  language?: string;
  websiteLanguage?: string;
  mobile?: string;
  phone?: string;
  /* ToBeDEL */ roles?: {}; // enum: ['user', 'admin']
  groups?: UserGroup[]; // enum: ['resellr-candidate', 'reseller', 'owner', 'event-master', 'event-manager', 'interpreter', 'admin', 'operator']

  paymentMethod?: any;
  /*
  paymentMethod?: {
    card?: {
      last4?: string,
      brand?: string,
      expMonth?: number,
      expYear?: number,
      funding?: string,
      stripeCustomerId?: string
    },
    paypal?: {
      email: string
    },
    bank: {
      bankNumber?: string,
      bankAddress?: string,
      iban?: string,
      bic?: string,
      accountNumber?: string,
      accountName?: string,
      clearingCountry?: string,
    },
    selected: PaymentMethod
  };
  */

  /*
  payOutPreferences
    paypal
      email
    bank
      bankNumber
      bankAddress
      iban
      bic
      accountNumber
      accountName
      clearingCountry
    selected
      enum: ['paypal', 'bank']
  owner
    subscription
      servicePlan
      purchasedDate: Date,
      remainingEvents
      remainingTranslationChannels
  */

  company?: string;
  billingAddress?: string;
  VATnumber?: string;

  reseller?: any;
  /*
  reseller?: {
    company?: string;
    phone?: string;
    website?: string;
    translationsByYear?: string;
    services?: {
      techonsite?: boolean;
      techweb?: boolean;
      audiovideo?: boolean;
      lighting?: boolean;
      translation?: boolean;
      remotebooths?: boolean;
      staging?: boolean;
      management?: boolean;
      hostessing?: boolean;
    };
    sizes?: {
      small?: boolean;
      medium?: boolean;
      large?: boolean;
      xlarge?: boolean;
    };
    areas?: {
      worldwide?: boolean;
      //continents?: any;
      //continents?: [object];
      continents?: [{
        code?: string;
        name?: string;
        countries?: [{
          code?: string;
          name?: string;
          continent?: string;
          regions?: [{
            code?: string;
            name?: string;
            selected?: boolean;
          }];
          all?: boolean;
          selected?: boolean;
        }];
        all?: boolean;
        selected?: boolean;
      }];
    };
    notes?: string;
    accredited?: string;
  };
  */

  owner?: string;
  team?: {
    eventMasters?: User[];
    eventManagers?: User[];
    interpreters?: User[];
  };
  // Interpreter only
  interpreter?: {
    // Languages the interpreter speaks
    languages?: string[];
    // Score updated by each event performance
    score?: number;
    note?: string;
    // List of event managers which the interpreter belongs
    // ???useful???
    eventManagers?: User[];
  };
  balance?: number;
  discount?: number;
  // Interpreter id from ablio database (not required)
  ablioId?: string;
  status?: string; //enum: ['newlycreated', 'invited', 'active', 'sleeping', 'unpaid-billing', 'inreview', 'suspended', 'blocked', 'abusive']
  //created
  passwordResetToken?: string;
  passwordResetExpires?: string;
  //updated

  constructor(user: any = null) {
    if (!user) {
      return;
    }

    this._id = user._id;
    this.ablioId = user.ablioId;
    this.username = user.username;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.password = user.password;
    this.singularity = user.singularity;
    this.passwordResetToken = user.passwordResetToken;
    this.passwordResetExpires = user.passwordResetExpires;
    this.terms = user.terms;
    //TODO: this.address = user.address;
    this.zip = user.zip;
    this.city = user.city;
    this.state = user.state;
    this.country = user.country;
    this.language = user.language;
    this.websiteLanguage = user.websiteLanguage;
    this.mobile = user.mobile;
    this.phone = user.phone;
    //this.roles = user.roles;
    this.groups = user.groups;
    if (user.owner) this.owner = user.owner;
    if (user.team) this.team = user.team;
    if (user.balance) this.balance = user.balance;
    if (user.discount) this.discount = user.discount;
    this.status = user.status;
    // NOT USED: this.eventMasters = user.eventMasters;
    // NOT USED: this.isVenueManager = user.isVenueManager;
    // NOT USED: this.isSystemManager = user.isSystemManager;
    // NOT USED: this.isTranslationManager = user.isTranslationManager;
    // NOT USED: this.isAudienceManager = user.isAudienceManager;
    this.interpreter = user.interpreter;
    if (user.paymentMethod) this.paymentMethod = user.paymentMethod;
    if (user.company) this.company = user.company;
    if (user.billingAddress) this.billingAddress = user.billingAddress;
    if (user.VATnumber) this.VATnumber = user.VATnumber;
    if (user.reseller) this.reseller = user.reseller;
  }

  /**
   * Instance methods below
   * "this.user = new User()" is needed to use the instance methods
   */

  // Methods to check if user belongs to group
  isReseller() {
    return (this.groups && this.groups.indexOf(UserGroup.RESELLER) > -1);
  }

  isResellerCandidate() {
    return (this.groups && this.groups.indexOf(UserGroup.RESELLER_CANDIDATE) > -1);
  }

  isOwner() {
    return (this.groups && this.groups.indexOf(UserGroup.OWNER) > -1);
  }

  isEventMaster() {
    return (this.groups && this.groups.indexOf(UserGroup.EVENT_MASTER) > -1);
  }

  isEventManager() {
    return (this.groups && this.groups.indexOf(UserGroup.EVENT_MANAGER) > -1);
  }

  isInterpreter() {
    return (this.groups && this.groups.indexOf(UserGroup.INTERPRETER) > -1);
  }

  isAdmin() {
    return (this.groups && this.groups.indexOf(UserGroup.ADMIN) > -1);
  }

  isOperator() {
    return (this.groups && this.groups.indexOf(UserGroup.OPERATOR) > -1);
  }

  /*
  isAudience() {
    return (this.groups && this.groups.indexOf(UserGroup.AUDIENCE) > -1);
  }
  */

  // Returns event masters plus user himself
  getEventMasters() {
    if (this.team && this.team.eventMasters) {
      const eventMasters = Object.assign([], this.team.eventMasters);
      if (!eventMasters.some(e => e._id === this._id)) {
        eventMasters.push(this);
      }
      return eventMasters;
    } else {
      return null;
    }
  }

  // Returns event managers plus user himself
  getEventManagers() {
    if (this.team && this.team.eventManagers) {
      const eventManagers = Object.assign([], this.team.eventManagers);
      if (!eventManagers.some(e => e._id === this._id)) {
        eventManagers.push(this);
      }

      return eventManagers;
    } else {
      return null;
    }
  }

  // Returns the master passed by id if belongs to user team
  getMaster(id) {
    const eventMasters = this.getEventMasters();
    if (eventMasters === null) {
      return null;
    } else {
      for (let i = 0; i < eventMasters.length; i++) {
        if (eventMasters[i]._id === id) {
          return eventMasters[i];
        }
      }
      return null;
    }
  }

  // Returns the master passed by id if belongs to user team
  getManager(id) {
    const eventManagers = this.getEventManagers();
    if (eventManagers === null) {
      return null;
    } else {
      for (let i = 0; i < eventManagers.length; i++) {
        if (eventManagers[i]._id === id) {
          return eventManagers[i];
        }
      }
      return null;
    }
  }
}
