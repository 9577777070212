import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getTranslationProviders } from './i18n.providers';

if (environment.enableProdMode) {
  enableProdMode();
}

platformBrowserDynamic()
.bootstrapModule(AppModule, getTranslationProviders())
.catch(err => console.log(err));
