import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {TitleCasePipe} from '@angular/common';

/**
 * Directive used to force titlecase in a text input
 *
 * Usage: Add the attribute appTitleCase to the text input element
 * Example: <input type="text" class="form-control" [(ngModel)]="name" appTitleCase>
 */
@Directive({
  selector: '[appTitleCase]'
})
export class TitleCaseDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  @HostListener('input', ['$event']) onInputChange($event) {
    this.value = new TitleCasePipe().transform($event.target.value);
    this.ngModelChange.emit(this.value);
  }
}
