import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { EventService } from '../services/event/event.service';

@Injectable()
export class EventGuard implements CanActivate, OnDestroy {
  private subscriptions = new Subscription();

  constructor(private router: Router, private eventServ: EventService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.checkEvent(next);
  }

  checkEvent(next: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.subscriptions.add(this.eventServ.eventChange.subscribe(
        (event) => {
          if (event && event._id) {
            return observer.next(true);
          }
          const id = next.paramMap.get('id');
          this.eventServ.getEvent(id, (err, data) => {
            if (err) {
              console.log(err);
              // Navigate to the home page
              this.router.navigate(['/']);
              return observer.next(false);
            }
            return observer.next(true);
          });
        }));
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
