import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { Stats } from 'app/models/Stats';
import { KurentoService } from '../../../../services/kurento/kurento.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kurento-stats',
  templateUrl: './kurento-stats.component.html',
  styleUrls: ['./kurento-stats.component.css']
})
export class KurentoStatsComponent implements OnInit, OnDestroy {
  sub: any;
  langArray: any;
  statsMap: any = {
    speaker: {},
    interpreter: null,
    audience: null
  };
  event: Event;
  eventId: string;
  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute
  ) {
    this.subscriptions.add(KurentoService.statsMapChange.subscribe((value) => {
      this.statsMap = value;
      // console.log(this.statsMap.interpreter);
      this.langArray = Object.keys(this.statsMap.interpreter);
      // console.log(this.langArray);
      // this.speakerStreamingToggle();
    }));
  }

  ngOnInit() {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // sets the event param in the proper service attribute
        this.eventId = params['event'] || '5a8f837473e4f408b03eda96';
      });

    // Event status polling
    setInterval(() => {
      this.getStats();
    }, 5000);
  }

  getStats() {
    // this.eventServ.getEvent(this.eventId, (err, data) => {
    // this.event = data;
    KurentoService.eventStats(this.eventId);
    // });
    // KurentoService.endpointStats(this.eventId, 'interpreter', 'it_IT', 0);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
