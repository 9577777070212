import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
//import {interval} from "rxjs/internal/observable/interval";
//import {startWith, switchMap} from "rxjs/operators";
import { SoundMeter } from '../../../../libs/soundmeter';
import { Event } from '../../../../models/Event';
import { User } from '../../../../models/User';
import { ChatService } from '../../../../services/chat/chat.service';
import { EventService } from '../../../../services/event/event.service';
import { KurentoService } from '../../../../services/kurento/kurento.service';
import { SocketsService } from '../../../../services/sockets/sockets.service';
import { UserService } from '../../../../services/user/user.service';
import { FlashMessagesService } from '../../../../services/util/flash-messages.service';
import { LanguagesService } from '../../../../services/util/languages.service';
import { WebRtcPeer } from '../../../../libs/WebRtcPeer';
import { VideoElementService } from '../../../../services/util/video-element.service';
import { OsService } from '../../../../services/util/os.service';

declare var console: any;
// TODO (micSel): var self: any;

@Component({
  selector: 'app-interpreter',
  templateUrl: './interpreter.component.html',
  styleUrls: ['./interpreter.component.css']
})
export class InterpreterComponent implements OnInit, OnDestroy {
  static readonly ORIGINAL_LANGUAGE = 'O';

  user: User;
  eventId: string;
  event: Event;
  eventStarted: boolean;
  languages: string[];
  langPair: string;
  activeLang: string;
  activeRelayCode: string;
  //code: string;
  channelURL: SafeResourceUrl;
  sub: any;
  showTakeAudio: boolean;
  showTakeAudioTimeout: any;
  takingAudio: boolean;
  connecting: boolean;
  disconnecting: boolean;

  speakerStreaming: any;
  interpreterJoined: boolean;
  interpreterStreaming: { [key: string]: boolean; } = {};
  interpreterDashStreaming: any;
  interpreterForbidden = false;
  interpreterOnAir: boolean;

  subscriptions = new Subscription();

  video: any;
  audio: any;
  // TODO (micSel): micSelector: any
  relayAudio: any;
  otherInterpreterAudio: any;
  speakerVolume: any;
  otherInterpreterVolume: any;

  message: string;

  senderEnabled = true;
  viewerEnabled = true;
  viewerPlaying: boolean;
  stopSenderEnabled: boolean;
  connectingOtherInterpreter = false;
  otherInterpreterConnected: boolean;
  otherInterpreterEnabled = true;
  otherInterpControlEnabled = true;
  takeEnabled: boolean;
  switchingLang: boolean;
  switchDisabled: boolean;
  audioMuted = false;
  socketWasReady = false;
  connectedAndLostInternet = false;
  resetTime = 3600000; // 60 minutes (1 hour)
  resetConnectionTimeout;
  resetSilenceTime = 10000; // 10 secs
  resetConnSilenceTimeout;

  speakerConstraints: Object;
  audioConstraints: Object = {
    audio: {
      echoCancellation: false,
      autoGainControl: false,
      noiseSuppression: false
    },
    video: false
  };

  webRtcPeerSend: WebRtcPeer;
  webRtcPeerReceiveSpeaker: WebRtcPeer;
  webRtcPeerReceiveOtherInterp: WebRtcPeer;

  meterRefresh: any;
  soundMeter: any;
  interpreterSilence: boolean;

  @ViewChild('langCustomSwitch') langCustomSwitch: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private eventServ: EventService,
    private kurento: KurentoService,
    private socketsServ: SocketsService,
    private userServ: UserService,
    private flashMessagesServ: FlashMessagesService,
    private videoElementServ: VideoElementService,
    private osServ: OsService,
    public chatServ: ChatService,
    public langsServ: LanguagesService
  ) {
    /*
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    */

    this.langPair = this.route.snapshot.paramMap.get('langs');
    // runs the ws for interpreters with the proper lang param
    this.languages = this.langPair.split('-');
    KurentoService.startWsAudio(this.languages[this.activeLang], null);

    // sets the event param in the proper service attribute
    KurentoService.eventId = this.eventId = this.route.snapshot.paramMap.get('eventId');

    // Selecting second language as default - TODO sync with backend??
    KurentoService.lang = this.activeLang = this.languages[1];

    // Creating unique code for the audience user to get a language of a specific event
    //this.code = KurentoService.eventId +'_'+this.languages[this.activeLang];

    // Sets the default input speaker audio as original
    this.activeRelayCode = InterpreterComponent.ORIGINAL_LANGUAGE;

    // Loads event data
    this.eventServ.getEvent(this.eventId, (err, event) => {
      if (err) {
        throw new Error(err);
      }
      this.event = event;
      console.warn(this.event);
    });

    this.route.queryParams.subscribe(params => {
      this.channelURL = KurentoService.getProviderURL(params, this.sanitizer);

      this.subscriptions.add(KurentoService.eventStartedChange.subscribe((value) => {
        // Stops all webRTC communications when event is stopped
        if (value && !this.eventStarted) {
          //this.router.navigated = false;
          //this.router.navigate([this.router.parseUrl(this.router.url)]);

          // Loads user data
          this.subscriptions.add(this.userServ.userChange.subscribe((user) => {
            if (user) {
              // Registers the interpreter in the proper language pair
              this.user = user;
              KurentoService.interpreterJoin(this.eventId, user._id, this.languages);
            }
          }));
        } else if (!value && this.eventStarted) {
          this.stop();
          this.stopAudio();
          this.videoElementServ.hideSpinner(this.video);

          this.viewerEnabled = true;
          this.viewerPlaying = false;
          this.interpreterDashStreaming = {
            connected: false,
            isOnAir: false
          };
          this.interpreterOnAir = false;
          this.otherInterpreterEnabled = true;
          this.otherInterpControlEnabled = true;
          this.switchingLang = false;

          this.disposeWebRtcPeers();
          //location.reload();

          // Unregisters the interpreter in the proper language pair
          //KurentoService.interpreterUnjoin(this.eventId, this.languages);
        }

        this.eventStarted = value;
      }));
    });

    this.subscriptions.add(KurentoService.speakerStreamingChange.subscribe((value) => {
      this.speakerStreaming = value;
      this.speakerStreamingToggle();
    }));

    this.subscriptions.add(KurentoService.speakerConstraintsChange.subscribe((value) => {
      this.speakerConstraints = value;
      this.speakerConstraintsToggle();
    }));

    this.subscriptions.add(KurentoService.interpreterJoinedChange.subscribe((value) => {
      this.interpreterJoined = value;
    }));

    this.subscriptions.add(KurentoService.interpreterStreamingChange.subscribe((data) => {
      console.warn('interpreterStreamingChange:', data.value);
      this.interpreterStreaming = data.value;
      this.otherInterpStreamingToggle(data.response);
    }));

    this.subscriptions.add(KurentoService.interpreterRelayChange.subscribe((value) => {
      console.warn('interpreterRelayChange:', value);
      this.interpreterStreaming = value;
      this.interpreterRelayToggle();
    }));

    this.subscriptions.add(KurentoService.activeLangChange.subscribe((value) => {
      this.activeLang = value;
      this.switchLang(false, null);
    }));

    this.subscriptions.add(KurentoService.interpreterDashStreamingChange.subscribe((data) => {
      console.warn('interpreterDashStreamingChange:', data);
      this.interpreterDashStreaming = data;
      this.interpreterDashStreamingToggle(data);
      this.initLocalVolumeMeter();
    }));

    this.subscriptions.add(KurentoService.interpreterStoppedChange.subscribe((value) => {
      this.senderEnabled = value;
      this.interpreterStreamingStop(value);
    }));

    this.subscriptions.add(KurentoService.interpreterForbiddenChange.subscribe((value) => {
      this.interpreterForbidden = value;
      this.interpreterStreamingForbidden();
    }));

    this.subscriptions.add(KurentoService.interpreterOnAirChange.subscribe((value) => {
      const changed = this.interpreterOnAir !== value;
      this.interpreterOnAir = value;
      this.interpreterOnAirToggle(changed);
      //this.interpreterStreamingForbidden();
      this.unmuteAudio();
    }));

    this.subscriptions.add(KurentoService.errorProcessingAnswerOnAudienceResponse.subscribe(() => {
      this.retryConnectOtherInterp();
    }));

    this.subscriptions.add(this.socketsServ.ready.subscribe((isReady) => {
      if (isReady) {
        this.socketWasReady = true;
        if (this.connectedAndLostInternet) {
          // Reload page to clear dashboard after interpreter is reconnected
          location.reload();
        }
        this.subscriptions.add(this.socketsServ.socketMessageReceive.subscribe((data) => {
          if (!data) {
            return;
          }
          if (data.messageId === 'takeAudio') {
            this.flashMessagesServ.warning('Please take the mic');
            // the take button will blink for 15 seconds
            this.showTakeAudio = true;
            this.showTakeAudioTimeout = setTimeout(() => {
              this.showTakeAudio = false;
            }, 15000);
          }
        }));
      }
      if (!isReady) {
        // At this point we assume the interpreter has been disconnected, so there is a call in backend to stop this
        // interpreter and force the boothmate to take (as if the interpreter closes the tab or reloads the page)
        this.connectedAndLostInternet = this.socketWasReady;
      }
    }));
  }

  ngOnInit() {
    // Event status polling
    KurentoService.checkEventStatus(false, 30000);

    this.video = document.getElementById('video-speaker');
    this.audio = document.getElementById('audio-interpreter');
    // TODO (micSel): this.micSelector = document.getElementById('mic-selector');
    this.otherInterpreterAudio = document.getElementById('audio-other-interpreter');
    this.relayAudio = document.getElementById('audio-relay');
    this.speakerVolume = document.getElementById('volume-speaker');
    this.speakerVolume.previousValue = 1;
    this.otherInterpreterVolume = document.getElementById('volume-other-interpreter');
    this.otherInterpreterVolume.previousValue = 1;

    KurentoService.startWsIfNotRunning();
    /* TODO (micSel):
    self = this;
    this.loadSources();
    navigator.mediaDevices.addEventListener('devicechange', this.loadSources);
    */

    // Hide top header
    document.getElementById('topHeader').classList.add('d-none');

    // Time to reset connection is by default 60 minutes (1 hour). If the OS is Mac, then 30 minutes.
    if (this.osServ.isMac()) {
      this.resetTime = 1800000; // 30 minutes
    }
  }

  isViewerEnabled() {
    return this.viewerEnabled;
  }

  isSenderEnabled() {
    return this.senderEnabled;
  }

  /*
  isStopSenderEnabled() {
    return this.stopSenderEnabled;
  }
  */

  isOtherInterpreterEnabled() {
    return this.otherInterpreterEnabled;
  }

  isTakeEnabled() {
    return this.takeEnabled;
  }

  viewer() {
    this.videoElementServ.showSpinner(this.video);
    // view speaker will always try to watch and listen to the real speaker source, not a relay, so it sets the relay
    // selector accordingly
    this.activeRelayCode = InterpreterComponent.ORIGINAL_LANGUAGE;
    this.viewerEnabled = false;
    this.viewerPlaying = true;
    // Listen to original speaker audio/video
    KurentoService.onViewerConstraints();
    this.setOriginalAudio();
  }

  stop() {
    if (KurentoService.webRtcPeerAudience) {
      this.disposeWebRtcPeerReceiveSpeaker();
    }
  }

  connectOtherInterp() {
    if (this.connectingOtherInterpreter || this.otherInterpreterConnected || !this.takeEnabled) {
      return;
    }

    this.connectingOtherInterpreter = true;
    this.otherInterpreterEnabled = false;

    KurentoService.lang = this.activeLang;

    const options = {
      remoteVideo: this.otherInterpreterAudio,
      onicecandidate: KurentoService.onIceCandidateAudio
    };
    this.disposeWebRtcPeerReceiveOtherInterp();
    KurentoService.webRtcPeerAudience = this.webRtcPeerReceiveOtherInterp = WebRtcPeer.Recvonly(options, (error) => {
      this.connectingOtherInterpreter = false;
      if (error) {
        throw new Error(error);
      }
      this.otherInterpreterConnected = true;
      console.warn('connecting to other interpreter audio…');
      this.playOtherInterp(true);
      this.webRtcPeerReceiveOtherInterp.generateOffer(KurentoService.onOfferViewerAudio);
    });
  }

  retryConnectOtherInterp() {
    this.disposeWebRtcPeerReceiveOtherInterp();
    this.connectingOtherInterpreter = false;
    this.connectOtherInterp();
  }

  /*
  stopOtherInterp() {
    this.otherInterpreterEnabled = true;
    KurentoService.onStopAudio(true);
  }
  */

  playOtherInterp(val) {
    if (val) {
      // if the interpreter is on air cannot play himself (also the play button is disabled but this is a double check)
      if (this.interpreterOnAir) {
        return;
      }
      this.otherInterpreterAudio.play();
    } else {
      this.otherInterpreterAudio.pause();
    }
  }

  getInactiveLangCode() {
    const langId = this.languages.indexOf(this.activeLang);
    if (langId === 0) {
      return this.languages[1];
    } else if (langId === 1) {
      return this.languages[0];
    }
  }

  switchLang(local: boolean, $event) {
    this.switchDisabled = true;
    const langToSwitch = this.getInactiveLangCode();

    this.eventServ.isLangOnAir(langToSwitch, this.langPair, (error, response) => {
      if (!error && response.langOnAir) {
        if ($event) {
          $event.srcElement.checked = !$event.srcElement.checked;
        }
        // Language to switch is already on air, stop switching
        this.flashMessagesServ.error('Trying to switch to a language that is already on air');
        this.switchDisabled = false;
        return;
      }

      setTimeout(() => {
        // If after switching there was listening to a relay, we need to restart playing it
        if (this.activeRelayCode !== InterpreterComponent.ORIGINAL_LANGUAGE) {
          this.speakerAudioSrcChange();
        }
        // After switching we need to keep listen to boothmate
        this.otherInterpreterConnected = false;
        this.switchDisabled = false;
        console.warn('connecting to other interpreter after lang switch…');
        this.connectOtherInterp();
      }, 4000); // Time needs to wait until interpreter is transmitting again

      // If is on air...
      if (!this.isSenderEnabled()) {
        // Stops the transmission
        this.stopAudioForSwitchLang();

        // Make disabled all streaming buttons
        this.switchingLang = true;
      }

      if (local) {
        this.activeLang = this.getInactiveLangCode();

        if (this.activeLang) {
          KurentoService.lang = this.activeLang;
          KurentoService.onSwitchLanguage(this.eventId, this.languages, this.activeLang);
        }
      }

      //this.code = KurentoService.eventId +'_'+this.languages[this.activeLang];
      console.log('Active language new: ' + this.activeLang);
      this.flashMessagesServ.info('Output language switched to ' + this.langsServ.getEngName(this.activeLang));

      // Restarts the transmission if was already active
      if (!this.isSenderEnabled()) {
        let timeout = 10;
        if (!local) {
          timeout = 2000;
        }

        setTimeout(() => {
          this.sendAudio();
          // Activate Listen button
          this.otherInterpreterEnabled = true;
        }, timeout);

      }
    });
  }

  setOriginalAudio() {
    this.video.volume = this.speakerVolume.value;
    this.relayAudio.volume = 0;
    this.relayAudio.pause();
  }

  setRelayAudio() {
    this.relayAudio.volume = this.speakerVolume.value;
    this.video.volume = 0;
    this.relayAudio.play();
  }

  speakerAudioSrcChange() {
    console.warn('Speaker input language changed:' + this.activeRelayCode);

    const langCode = this.activeRelayCode;
    if (langCode === InterpreterComponent.ORIGINAL_LANGUAGE) {
      if (!this.speakerStreaming) {
        this.viewer();
      }
      this.setOriginalAudio();
    } else {

      // Using KurentoService.relayLang instead of KurentoService.lang because if we call listen to other interpreter
      // audio and listen to relay at almost same time, both using KurentoService.lang, then the value will be overwritten
      // when this.generateOffer is called (Using the same lang for both calls). Which produce unexpected behaviours.
      KurentoService.relayLang = langCode;

      const options = {
        remoteVideo: this.relayAudio,
        onicecandidate: KurentoService.onIceCandidateAudio
      };
      //this.disposeWebRtcPeerReceiveSpeaker();
      //let _self = this;
      KurentoService.webRtcPeerInterpRcv = this.webRtcPeerReceiveSpeaker = WebRtcPeer.Recvonly(options, function (error) {
        if (error) {
          throw new Error(error);
        }

        this.generateOffer(KurentoService.onOfferViewerAudioRelay);
        //_self.interpreterRelayToggle();
      });
    }
  }

  /* TODO (micSel):
  changeSource() {
    const micSource = this.micSelector.value;
    this.audioConstraints['audio']['deviceId'] = micSource;
  }

  loadSources() {
    navigator.mediaDevices.enumerateDevices()
      .then((devicesInfo) => {
        self.gotDevices(devicesInfo);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  gotDevices(devicesInfo) {
    console.warn(devicesInfo);
    const value = this.micSelector.value;
    while (this.micSelector.firstChild) {
      this.micSelector.removeChild(this.micSelector.firstChild);
    }
    for (let i = 0; i !== devicesInfo.length; ++i) {
      const deviceInfo = devicesInfo[i];
      if (deviceInfo.kind === 'audioinput') {
        const option = document.createElement('option');
        option.value = deviceInfo.deviceId;
        option.text = deviceInfo.label || `microphone ${ this.micSelector.length + 1 }`;
        this.micSelector.appendChild(option);
      }
    }
    if (Array.prototype.slice.call(this.micSelector.childNodes).some(n => n.value === value)) {
      this.micSelector.value = value;
    }
  }
  */

  sendAudio() {
    this.connecting = true;
    if (!this.activeLang) {
      return;
    }

    this.eventServ.isLangOnAir(this.activeLang, this.langPair, (error, response) => {
      if (!error && response.langOnAir) {
        // Trying to connect to a language already on air, switching language to let the interpreter try again
        this.activeLang = this.getInactiveLangCode();
        this.flashMessagesServ.error('Trying to connect to a language that is already on air. Language switched');
        return;
      }
      this.sendOrTakeAudio({ localVideo: this.audio }, KurentoService.onOfferPresenterAudio);
    });
  }

  stopAudio(switchActive = true) {
    this.disconnecting = true;
    this.senderEnabled = true;
    this.stopSenderEnabled = false;
    this.takeEnabled = false;
    this.unmuteAudio();
    KurentoService.onStopAudio(switchActive);

    // Stopping soundMeter for interpreter mic
    if (this.soundMeter) {
      this.soundMeter.stop();
    }

    clearInterval(this.meterRefresh);
    this.disposeWebRtcPeerSend();
  }

  stopAudioForSwitchLang() {
    this.disposeWebRtcPeerSend();
    KurentoService.onStopAudio(false, true);
  }

  /*
  stopSendingAudio() {
    setTimeout(() => {
      this.senderEnabled = true;
    }, 2500);

    this.stopSenderEnabled = false;

    this.dispose();
    KurentoService.onStopAudio(true);
  }
  */

  takeAudio() {
    if (this.takingAudio) {
      // Already called because other interpreter disconnected or resetConnectionTimeout triggered
      return;
    }
    this.takingAudio = true;
    this.chatServ.clearBlinkingOnTake();
    // Doing interpreter disconnection (stopAudio) and reconnection (sendAudio) to patch the problem of audio degradation
    // over time with macos and chrome
    this.stopAudio(false);
    // After receiving stopInterpreterSndResponse, then we can CONNECT (sendAudio) the interpreter. Look at
    // interpreterStreamingStop() called from interpreterStoppedChange subscription.
    // After receiving interpreterSndResponse, then we can take audio (finishTakeAudio). Look at
    // interpreterDashStreamingToggle() called from interpreterDashStreamingChange subscription.
  }

  finishTakeAudio() {
    clearTimeout(this.showTakeAudioTimeout);
    this.showTakeAudio = false;
    if (!this.webRtcPeerSend) {
      this.flashMessagesServ.error('There was an error while taking audio. You will be disconnected, please connect again. ERROR: no webRtcPeer');
      // Disconnecting interpreter to force a manual reconnection and to make the boothmate to take the audio
      this.stopAudio();
      return;
    }
    this.webRtcPeerSend.generateOffer(KurentoService.onTakeAudio);
  }

  passAudio() {
    if (!this.chatServ.boothMate.online) {
      return;
    }
    this.socketsServ.sendMessage({
      from: this.user._id,
      messageId: 'passAudio',
      to: this.chatServ.boothMate.id
    });
    this.flashMessagesServ.success('A message was sent to your booth mate asking to take the audio.');
  }

  muteUnmuteAudio() {
    if (!this.webRtcPeerSend) {
      return;
    }

    this.audioMuted = !this.audioMuted;
    this.webRtcPeerSend.muteUnmuteAudio(this.audioMuted);
  }

  unmuteAudio() {
    if (!this.webRtcPeerSend) {
      return;
    }

    this.audioMuted = false;
    this.webRtcPeerSend.muteUnmuteAudio(this.audioMuted);
  }

  /*
  stopViewer() {
    this.dispose();
  }
  */

  speakerUnavailableMsg(check: any) {
    if (!check) {
      this.viewerEnabled = true;
      this.stop();
      this.flashMessagesServ.warning('Cannot receive speaker A/V. Please try later…');
      this.videoElementServ.hideSpinner(this.video);
    }
  }

  speakerStreamingToggle() {
    console.log('INTERPRETER - Is speaker streaming? ' + this.speakerStreaming);

    this.viewerEnabled = false;
    this.speakerUnavailableMsg(this.speakerStreaming);
  }

  speakerConstraintsToggle() {
    this.speakerUnavailableMsg(this.speakerConstraints);

    if (this.speakerConstraints) {
      const options = {
        mediaConstraints: this.speakerConstraints,
        remoteVideo: this.video,
        onicecandidate: KurentoService.onIceCandidate
      };

      console.log('viewer options:');
      console.warn(options);

      //this.disposeWebRtcPeerReceiveSpeaker();
      KurentoService.webRtcPeerInterpRcv = this.webRtcPeerReceiveSpeaker = WebRtcPeer.Recvonly(options, function (error) {
        if (error) {
          throw new Error(error);
        }

        this.generateOffer(KurentoService.onOfferViewer);
      });
    }
  }

  interpreterRelayToggle() {
    const code = this.eventId + '_' + this.activeRelayCode;
    console.log('INTERPRETER: interpreterRelayToggle: - Is interpreter streaming? ' + this.interpreterStreaming[code]);

    if (this.interpreterStreaming[code] === undefined) {
      return;
    }

    if (this.interpreterStreaming[code]) {
      this.setRelayAudio();
      //this.flashMessagesServ.warning('WARNING: Interpreter has started streaming. Click on receive audio to listen the translation');
    } else {
      if (!this.speakerStreaming) {
        this.viewerEnabled = true;
        this.viewerPlaying = false;
      }
      this.setOriginalAudio();
      this.activeRelayCode = InterpreterComponent.ORIGINAL_LANGUAGE;
      //this.stop();
      return this.flashMessagesServ.warning('Cannot receive the selected audio relay. Please try later…');
    }
  }

  otherInterpStreamingToggle(response: string) {
    const code = this.eventId + '_' + this.activeLang;
    console.log('INTERPRETER - Is interpreter streaming? ' + this.interpreterStreaming[code]);

    if (!this.interpreterStreaming[code]) {
      this.otherInterpreterConnected = false;
    }
    if (response === 'rejected' && !this.interpreterOnAir) { // failed to connect other interpreter, retrying..
      setTimeout(() => {
        this.otherInterpreterEnabled = true;
        this.connectOtherInterp();
        this.playOtherInterp(true);
      }, 3000);
      return;
    }
    if (this.interpreterStreaming[code]) {
      this.otherInterpreterEnabled = false;
      //this.otherInterpControlEnabled = false;
      this.connectOtherInterp();
      this.playOtherInterp(true);
      //this.flashMessagesServ.warning("Interpreter has started streaming. Click on receive audio to listen the translation");
    } else if (!this.isOtherInterpreterEnabled()) {
      this.otherInterpreterEnabled = true;
      //this.otherInterpControlEnabled = true;
      this.playOtherInterp(false);
      //this.flashMessagesServ.warning('Cannot receive other interpreter audio. Please try later…');
    }
  }

  interpreterDashStreamingToggle(data) {
    this.switchingLang = false;
    clearTimeout(this.resetConnectionTimeout);
    clearTimeout(this.resetConnSilenceTimeout);
    //console.log("INTERPRETER - Is interpreter streaming? "+this.interpreterDashStreaming);
    if (this.interpreterDashStreaming && this.interpreterDashStreaming.connected) {
      this.senderEnabled = false;
      this.stopSenderEnabled = true;
      if (this.takingAudio) {
        // If it is taking audio, then sendAudio() was called automatically after stopAudio() so now it needs to take
        if (data.connected) {
          setTimeout(() => {
            this.finishTakeAudio();
          }, 100); // Waiting to receive the iceCandidateInterpSnd messages before taking audio
          // Reactivating reset connection after each take
          this.resetConnectionAfterResetTime();
        } else {
          // failed, interpreter not connected
          this.flashMessagesServ.error('Error connecting while taking audio. Check your internet connection and reset the page.');
        }
        return;
      }

      this.takeEnabled = !this.interpreterDashStreaming.isOnAir;
      this.connectOtherInterp();
      if (!this.takeEnabled) {
        // Activating reset connection after the first connect
        this.resetConnectionAfterResetTime();
      }
    } else {
      this.senderEnabled = true;
      this.stopSenderEnabled = false;
      this.takeEnabled = false;
      this.flashMessagesServ.warning('There is already another booth for this output language. Try again later…');
    }
  }

  interpreterStreamingStop(stopped: boolean) {
    this.disconnecting = false;
    if (this.takingAudio) {
      // If it is taking audio, stopAudio() was called automatically so now it needs to reconnect by calling sendAudio()
      if (stopped) {
        this.sendAudio();
      } else {
        this.flashMessagesServ.error('Stop failed while taking audio. Check your internet connection and reset the page.');
      }
      return;
    }

    //this.dispose();
    this.takeEnabled = false;
  }

  interpreterStreamingForbidden() {
    //console.log("INTERPRETER - Is interpreter streaming? "+this.interpreterStreaming[this.languages[this.activeLang]]);

    //this.receiverEnabled = false;

    if (!this.interpreterForbidden) {
      //this.stopReceiverEnabled = true;
      //this.flashMessagesServ.warning("Interpreter has started streaming. Click on receive audio to listen the translation");
    } else {
      //this.stopReceiverEnabled = false;
      this.stop();
      this.senderEnabled = true;
      this.flashMessagesServ.warning('Cannot add another ' + this.activeLang + ' interpreter audio source. There are already 2 interpreters connected. Please try later…');
    }
  }

  interpreterOnAirToggle(changed: boolean) {
    if (this.interpreterOnAir) {
      if (!this.takingAudio && !this.connecting && changed) {
        // interpreter switched to ON AIR because boothmate was disconnected, so now we call takeAudio() to disconnect
        // and connect in case of audio degradation problem is happening
        this.takeAudio();
        return;
      }
      this.takingAudio = false;
      this.takeEnabled = false;
      this.otherInterpControlEnabled = false;
      this.disposeWebRtcPeerReceiveOtherInterp();
      if (this.activeRelayCode !== InterpreterComponent.ORIGINAL_LANGUAGE) {
        this.speakerAudioSrcChange();
      }
    } else {
      this.takeEnabled = true;
      this.otherInterpControlEnabled = true;
      this.connectOtherInterp();
      this.playOtherInterp(true);
      clearTimeout(this.resetConnectionTimeout);
    }
    this.connecting = false;
  }

  speakerVolumeChange() {
    if (this.speakerVolume.value > 0) {
      this.speakerVolume.previousValue = this.speakerVolume.value;
    }
    if (this.activeRelayCode === InterpreterComponent.ORIGINAL_LANGUAGE) {
      this.video.volume = this.speakerVolume.value;
    } else {
      this.relayAudio.volume = this.speakerVolume.value;
    }
  }

  otherInterpVolumeChange() {
    if (this.otherInterpreterVolume.value > 0) {
      this.otherInterpreterVolume.previousValue = this.otherInterpreterVolume.value;
    }
    this.otherInterpreterAudio.volume = this.otherInterpreterVolume.value;
  }

  muteVolume(mute, volumeObj: string) {
    if (mute) {
      this[volumeObj].value = 0;
    } else {
      this[volumeObj].value = this[volumeObj].previousValue;
    }
    if (volumeObj === 'speakerVolume') {
      this.speakerVolumeChange();
    } else {
      this.otherInterpVolumeChange();
    }
  }

  dispose() {
    if (KurentoService.webRtcPeerInterpSnd) {
      KurentoService.webRtcPeerInterpSnd.dispose();
      KurentoService.webRtcPeerInterpSnd = null;
      this.disposeWebRtcPeers();
    }
    this.videoElementServ.hideSpinner(this.video);
    console.log('WebRTC peer disposed!');
  }

  unjoinInterpreter() {
    this.stopAudio();
    this.stop();

    // Unregisters the interpreter in the proper language pair
    KurentoService.interpreterUnjoin(this.eventId, this.languages);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    //this.unjoinInterpreter();
    this.stop();
    this.stopAudio();
  }

  private disposeWebRtcPeerSend() {
    console.warn('disposeWebRtcPeerSend');
    KurentoService.disposeWebRtcPeer(this.webRtcPeerSend);
  }

  private disposeWebRtcPeerReceiveSpeaker() {
    console.warn('disposeWebRtcPeerReceiveSpeaker');
    KurentoService.disposeWebRtcPeer(this.webRtcPeerReceiveSpeaker);
  }

  private disposeWebRtcPeerReceiveOtherInterp() {
    console.warn('disposeWebRtcPeerReceiveOtherInterp');
    KurentoService.disposeWebRtcPeer(this.webRtcPeerReceiveOtherInterp);
    this.otherInterpreterConnected = false;
  }

  private disposeWebRtcPeers() {
    console.warn('disposeWebRtcPeers');
    this.disposeWebRtcPeerSend();
    this.disposeWebRtcPeerReceiveSpeaker();
    this.disposeWebRtcPeerReceiveOtherInterp();
  }

  private sendOrTakeAudio(options, kurentoServiceMethod) {
    KurentoService.lang = this.activeLang;
    KurentoService.langPair = this.langPair;

    options.mediaConstraints = this.audioConstraints;
    options.onicecandidate = KurentoService.onIceCandidateAudio;

    const peer = WebRtcPeer.Sendonly(options, (error: any) => {
      // TODO: create all necessary webrtcpeers at the beginning when the interpreter does connect instead of creating a new one each switch, take, send
      // line 733 of WebRtcPeer.js never resolves the promise when tab is unfocused/inactive in Firefox: navigator.mediaDevices.getUserMedia(constraints).then(function (
      if (error) {
        const message = error.message ? ' Error: ' + error.message : '';
        this.flashMessagesServ.error('There was an error while taking audio. You will be disconnected, please connect again.' + message);
        // Disconnecting interpreter to force a manual reconnection and to make the boothmate to take the audio
        this.stopAudio();
        throw new Error(error);
      }
      if (this.webRtcPeerSend && this.webRtcPeerSend.peerConnection) {
        // closing previous RtcPeerConnection
        this.webRtcPeerSend.peerConnection.close();
      }
      KurentoService.webRtcPeerInterpSnd = this.webRtcPeerSend = peer;
      peer.generateOffer(kurentoServiceMethod);
      this.unmuteAudio();
      const localStream = KurentoService.webRtcPeerInterpSnd.getLocalStream();
      if (localStream) {
        console.warn('LOCAL STREAM:', localStream);
        console.warn('AUDIO TRACKS:', localStream.getAudioTracks());
      }
    });
  }

  // Reset connection after resetTime(ms) to patch the problem of audio degradation over time
  // this.resetTime is set to 30 minutes for Mac and 60 minutes (1 hour) for any other OS
  private resetConnectionAfterResetTime() {
    this.resetConnectionTimeout = setTimeout(() => {
      if (this.interpreterOnAir) {
        this.takeAudio();
      } else {
        this.stopAudio();
        this.sendAudio();
      }
    }, this.resetTime);
  }

  private resetConnectionAfterSilenceDetection() {
    this.resetConnSilenceTimeout = setTimeout(() => {
      //if (this.interpreterOnAir) {
      //  this.takeAudio();
      //} else {
      this.stopAudio();
      setTimeout(() => {
        this.sendAudio();
      }, 500);

      console.warn('Silence detected! Reconnecting audio…');
      //}
    }, this.resetSilenceTime);
  }

  private initLocalVolumeMeter() {
    const instantMeter: HTMLMeterElement = document.querySelector('#instant meter');
    //const slowMeter: HTMLMeterElement = document.querySelector('#slow meter');
    const clipMeter: HTMLMeterElement = document.querySelector('#clip meter');

    //const instantValueDisplay: HTMLElement = document.querySelector('#instant .value');
    //const slowValueDisplay: HTMLElement = document.querySelector('#slow .value');
    //const clipValueDisplay: HTMLElement = document.querySelector('#clip .value');

    //let meterRefresh = null;

    const stream = KurentoService.localStream;
    console.log('INTERPRETER LOCAL STREAM:', stream);

    //var mediaStream = audioContext.createMediaStreamSource(stream);
    //var volume = document.getElementById('volume');

    // Initializing soundMeter for interpreter mic
    const audioContext = new AudioContext();
    this.soundMeter = new SoundMeter(audioContext);

    // Starting soundMeter
    if (this.soundMeter) {
      this.soundMeter.connectToSource(stream, e => {
        if (e) {
          //alert(e);
          this.soundMeter.stop();
          this.soundMeter = null;
          return;
        }
        //const soundMeter = this.soundMeter;

        this.meterRefresh = setInterval(() => {
          //console.log(' ???DEBUG??? connected to source! soundmeter:', (this.soundMeter || 'undefined'));
          //instantMeter.value = instantValueDisplay.innerText = this.soundMeter.instant.toFixed(2);
          instantMeter.value = this.soundMeter.instant.toFixed(2);
          if (instantMeter.value && (instantMeter.value > 0) && (instantMeter.value < 0.7)) {
            instantMeter.value = Number(((1 - instantMeter.value) * 3.3 * instantMeter.value).toFixed(2));
          }
          //slowMeter.value = slowValueDisplay.innerText = this.soundMeter.slow.toFixed(2);
          //slowMeter.value = this.soundMeter.slow.toFixed(2);
          //clipMeter.value = clipValueDisplay.innerText = this.soundMeter.clip;
          clipMeter.value = this.soundMeter.clip;

          this.interpreterSilence = this.soundMeter.silence;
          // Reset connection if silence is detected
          if (!this.isSenderEnabled() && this.interpreterSilence && !this.audioMuted) {
            // TODO remove or fix function below
            //this.resetConnectionAfterSilenceDetection();
          }
        }, 100);
      });
    }
  }

  ngOnDestroy() {
    this.stop();

    // Show top header
    document.getElementById('topHeader').classList.remove('d-none');

    // Remove all the subscriptions
    this.subscriptions.unsubscribe();
  }
}
