import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  public visible = false;
  public visibleAnimate = false;
  private _largeModal: boolean;
  public fixed: boolean;

  @Input()
  get largeModal() {
    return this._largeModal;
  }

  set largeModal(value: any) {
    this._largeModal = this.coerceBooleanProperty(value);
  }

  // Method called to do something when the modal is hidden/closed. It will be always executed, no matter how you closed
  // the modal (if by clicking out of it, or by clicking some cancel or confirm button)
  // Usage: <app-modal #myModal (onHide)="doSomethingWhenModalIsHidden()">
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  private coerceBooleanProperty(value: any): boolean {
    return value != null && `${ value }` !== 'false';
  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
    this.onHide.emit();
  }

  public setFixed(fixed: boolean) {
    this.fixed = fixed;
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal') && !this.fixed) {
      this.hide();
    }
  }
}
